import type { ScontoCoupon } from "./promo/coupon";
import type { CustomerTripSummary, Viaggio } from "./trip/trip";
import type { RegistrationRequest, UserInfo } from "./user/user";

export interface SavableItem {
  shouldSave?: boolean;
}
export interface AccordionItem {
  accordionIsOpen?: boolean;
}

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

/**
 * Sorts trips putting trips of tomorrow on top
 * 1 - sort all the trips by date DESC
 * 2 - find trips between today and tomorrow
 * 3 - put the list on top of the trip list
 * 4 - merge the other trips
 */
export function sortTripList(tripList: Array<CustomerTripSummary>) {
  tripList = tripList.sort(
    (a, b) => new Date(b.data).getTime() - new Date(a.data).getTime(),
  );
  console.log(`Received trips: ${tripList.length}`);

  const date = new Date();
  date.setDate(date.getDate() + 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  const head = tripList
    .filter((t) => {
      const diff = new Date(t.data).getTime() - date.getTime();
      return (
        (diff <= ONE_DAY_MS && diff > 0) || (diff >= -ONE_DAY_MS && diff < 0)
      );
    })
    .sort((a, b) => new Date(a.data).getTime() - new Date(b.data).getTime());

  tripList = [
    ...head,
    ...tripList.filter((t) => !head.find((he) => he.idViaggio === t.idViaggio)),
  ];

  console.log(`Trips after sort: ${tripList.length}`);
  return tripList;
}

export function getVattedPrice(price: number, vat: number, round = true) {
  return round ? priceRound(price * (vat / 100 + 1)) : price * (vat / 100 + 1);
}

export function getTripFullPrice(
  trip: Viaggio,
  usedCredit: number,
  discount?: ScontoCoupon | null,
  vatAlreadyApplied?: boolean,
) {
  let tripPrice = trip.importo! !== 0 ? trip.importo! : -usedCredit; // usedCredit is negative

  let net = getNetPriceFromGross(tripPrice, trip.aliquotaIva);

  if (usedCredit && trip.importo! > 0) {
    net += -getNetPriceFromGross(usedCredit, trip.aliquotaIva); // to get the original price when using credit, need to do this because BE gives a gross price but it's wrong (right IVA = (net + usedCredit) * 1.1)
    tripPrice = getVattedPrice(net, trip.aliquotaIva);
  }

  const discountValue = getDiscountValueFromDiscountedTripNet(
    net,
    discount || null,
  );
  const initialNet = Math.round(net + discountValue);
  return getVattedPrice(initialNet, trip.aliquotaIva);
}

export function getNetPriceFromWrongGross(
  trip: Viaggio,
  usedCredit: number,
  round = true,
) {
  const tripPrice = trip.importo! !== 0 ? trip.importo! : -usedCredit; // usedCredit is negative

  let net = getNetPriceFromGross(tripPrice, trip.aliquotaIva, false);

  if (usedCredit && trip.importo! > 0) {
    net += -getNetPriceFromGross(usedCredit, trip.aliquotaIva, false); // to get the original price when using credit, need to do this because BE gives a gross price but it's wrong (right IVA = (net + usedCredit) * 1.1)
  }
  return round ? Math.round(net) : net;
}

export function getNetPriceFromGross(price: number, vat: number, round = true) {
  return round ? priceRound(price / (vat / 100 + 1)) : price / (vat / 100 + 1);
}

export function getDiscountedTripNet(
  vattable: number,
  discount: ScontoCoupon | null,
) {
  if (discount && discount.valore) {
    vattable -= discount.valore;
  } else if (discount && discount.percentuale) {
    vattable -= (vattable * discount.percentuale) / 100;
  }
  return vattable;
}

export function getTripDiscountValue(
  vattable: number,
  discount: ScontoCoupon | null,
  truncate = true,
) {
  if (discount && discount.valore) {
    return discount.valore;
  } else if (discount && discount.percentuale) {
    return (
      (truncate && priceRound((vattable * discount.percentuale) / 100)) ||
      (vattable * discount.percentuale) / 100
    );
  }
  return 0;
}

/**
 * Finds the applied discount from the discounted net (percetange discount)
 */
export function getDiscountValueFromTripNet(
  net: number,
  discount: ScontoCoupon | null,
  round = true,
) {
  if (discount?.valore) {
    return discount.valore;
  }
  if (discount?.percentuale) {
    return round
      ? priceRound((net * discount.percentuale) / 100)
      : (net * discount.percentuale) / 100;
  }
  return 0;
}

export function getDiscountValueFromDiscountedTripNet(
  net: number,
  discount: ScontoCoupon | null,
) {
  if (discount?.valore) {
    return discount.valore;
  }
  if (discount?.percentuale) {
    return priceRound((net * 100) / (100 - discount.percentuale) - net);
  }
  return 0;
}

/**
 * Truncate only last 2 decimal places of the price
 */
export function priceRound(price: number, decimalPlaces = 4) {
  const decimals = 10 ** decimalPlaces;
  return Math.trunc(Math.round(price * decimals)) / decimals;
}

export function userInfoToRegistrationRequest(
  userInfo: UserInfo,
  req: Partial<RegistrationRequest> = {},
): RegistrationRequest {
  return {
    phone: userInfo.phone,
    name: userInfo.payload.nome,
    surname: userInfo.payload.cognome,
    email: userInfo.email,
    idazienda: userInfo.payload.iD_Azienda,
    company: userInfo.payload.partitaIva,
    codiceFiscale: userInfo.payload.codFiscale,
    idcliente: userInfo.payload.idCliente,
    ...req,
  } as RegistrationRequest;
}

export function tripGrossPriceToEUR(trip: Viaggio) {
  return trip.importo! / trip.cambioValutaDaEuro;
}

export function getNationFromPlace(
  place: google.maps.GeocoderResult | google.maps.places.PlaceResult,
) {
  return (
    place.address_components?.find((ac) => ac.types.includes("country"))
      ?.short_name ?? "IT"
  );
}
