import { markRaw } from "vue";
import router from "../../router";

function RouterStorePlugin() {
  return {
    router: markRaw(router),
  };
}

export default RouterStorePlugin;
