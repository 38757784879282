import type { PiniaPluginContext } from "pinia";
import { CLIENT_NAME, CLIENT_VERSION } from "../../constants/utils";
import {
  TRIP_TO_INSERT_INITIAL_VALUE,
  type CentroNotifiche,
  type PreventivoModel,
  type Viaggio,
} from "../../models/trip/trip";
import type { useTripStore } from "../trip";
import type { AccordionItem } from "@syncfusion/ej2-vue-navigations";
import type { SavableItem } from "../../models/utils";
import type { Passeggero } from "../../models/user/passeggero";

export function TripStorePlugin({
  store,
}: PiniaPluginContext<"trip", ReturnType<typeof useTripStore>>) {
  if (store.$id === "trip") {
    if (!store.tripToInsert) {
      store.tripToInsert =
        store.getLocalTrip() ??
        ({ ...TRIP_TO_INSERT_INITIAL_VALUE } as Viaggio);

      const isMigration = store.tripToInsert.version !== CLIENT_VERSION;
      if (isMigration) {
        store.resetLocalStorage();
        store.tripToInsert = { ...TRIP_TO_INSERT_INITIAL_VALUE } as Viaggio;
      }

      const extras = store.tripToInsert.spieg_extra?.split(",");
      if (extras) {
        store.baggage =
          Number(
            extras
              .find((n) => n.includes("Bagagli da stiva"))
              ?.replace("Bagagli da stiva: ", ""),
          ) || 0;

        store.luggage =
          Number(
            extras
              .find((n) => n.includes("Bagagli a mano"))
              ?.replace("Bagagli a mano: ", ""),
          ) || 0;

        const babySeat = extras
          .find((n) => n.includes("Seggiolino"))
          ?.replace("Seggiolino: ", "")
          .trim();
        if (babySeat) {
          store.babySeats = [babySeat];
        }

        store.extraAssistance = extras.find((n) =>
          n.includes("Assistenza speciale"),
        )
          ? true
          : false;
      }
    }

    // if (!store.preventivo) {
    //   store.recoverPreventivoFromLocal();
    // }

    if (!store.rideType) {
      store.rideType = store.getLocalRideType();
    }

    const pricingsDataString = localStorage.getItem("pricings");
    if (pricingsDataString != null) {
      const pricingsData: {
        pricings: PreventivoModel[];
        selectedPricings: PreventivoModel[];
        passengersInCar: (Passeggero & AccordionItem & SavableItem)[];
        rideNotifications: Partial<CentroNotifiche>[];
        saveTime: number;
      } = JSON.parse(pricingsDataString);
      if (Date.now() - pricingsData.saveTime < 15 * 60 * 1000) {
        //less than 15 minutes
        store.preventivi = pricingsData.pricings || [];
        store.selectedPricings = pricingsData.selectedPricings || [];
        store.passengersInCar = pricingsData.passengersInCar || [];
        store.rideNotifications = pricingsData.rideNotifications || [];
      } else {
        localStorage.removeItem("pricings");
      }
    }

    store.$subscribe(() => {
      store.saveLocally();
    });
  }
}
