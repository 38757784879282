import type { AgencyBranding } from "../models/company/agencyBranding";
import type { MasterInfo } from "../models/info/master";
import Api, { parseApiResponse } from "./Api";

export default class CompanyApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + "v2/companies";

  async find({
    masterCode,
    companyId = 1,
  }: {
    masterCode: string;
    companyId?: number;
  }) {
    return parseApiResponse<AgencyBranding>(
      await this.get(
        this.baseApiUrl,
        `/find?masterCode=${masterCode}&idazienda=${companyId}`,
      ),
    );
  }

  async masterCodeExists({
    masterCode,
    companyId = 1,
  }: {
    masterCode: string;
    companyId?: number;
  }) {
    return parseApiResponse<boolean>(
      await this.get(
        this.baseApiUrl,
        `/mastercode/exists/${masterCode}?idazienda=${companyId}`,
      ),
    );
  }
}
