import { defineStore } from "pinia";
import ToastTripSuccess from "@/components/Utils/Toasts/ToastTripSuccess.vue";
import { POSITION } from "vue-toastification";
import { useTripStore } from "./trip";

type UiComponentData = {
  msg: string | null;
  loading: boolean;
  data: any;
  show: boolean;
};
type UiState = {
  globalSpinner: UiComponentData; // needed because Pinia auto state hydratation doesn't setup correctly the state with type [key: string]: UiComponentData
  wallPaper: UiComponentData;
  [key: string]: UiComponentData;
};

/**
 * UI store used to drive all the common (global) app components (spinners, error dialogs)
 */
export const useUiStore = defineStore("ui", {
  state: () =>
    ({
      globalSpinner: {
        data: {
          tasksToWait: 0,
        },
        loading: false,
        msg: null,
      },
      wallPaper: {
        data: {
          tasksToWait: 0,
        },
        loading: false,
        msg: null,
      },
    } as UiState),
  actions: {
    toggleLoading(show: boolean) {
      if (show) {
        if (!this.globalSpinner.loading) {
          this.globalSpinner.loading = true;
        }
        this.globalSpinner.data.tasksToWait++;
      } else if (show === false) {
        if (this.globalSpinner.data.tasksToWait !== 0) {
          this.globalSpinner.data.tasksToWait--;
        }
        if (this.globalSpinner.data.tasksToWait === 0) {
          this.globalSpinner.loading = false;
        }
      }
    },
    TripToastSuccess() {
      const tripStore = useTripStore();
      this.toast.success(ToastTripSuccess, {
        position: POSITION.TOP_CENTER,
        id: "book_return",
        timeout: 10000,
        icon: false,
        closeButton: false,
        toastClassName: "custom-success-toast",
        onClose() {
          if (
            tripStore.lastCreatedTrip.tripToInsert?.partenza !==
            tripStore.tripToInsert.partenza
          ) {
            tripStore.resetLastCreatedTrip();
          }
        },
      });
    },
    show() {
      this.globalSpinner.loading = true;
    },
    hide() {
      this.globalSpinner.loading = false;
    },
  },
  getters: {},
});
