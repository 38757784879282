import type { TipoServizioT } from "../models/service_type/service_type";
import Api, { parseApiResponse } from "./Api";

export default class ServiceTypeApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL;
  v2BaseApiUrl = this.baseApiUrl + "v2/tiposervizio";

  async allDispo(idazienda: number) {
    return parseApiResponse<Array<TipoServizioT>>(
      await this.get(this.v2BaseApiUrl, `/alldispo?idazienda=${idazienda}`),
    );
  }

  async allTours(idazienda: number) {
    return parseApiResponse<Array<TipoServizioT>>(
      await this.get(this.v2BaseApiUrl, `/alltour?idazienda=${idazienda}`),
    );
  }

  async journeyByPosition(
    customerId: number,
    lat: number,
    lng: number,
    companyId: number,
  ) {
    return parseApiResponse<Array<TipoServizioT>>(
      await this.get(
        this.v2BaseApiUrl,
        `/dispo?idcliente=${customerId}&lat=${lat}&lng=${lng}&idazienda=${companyId}`,
      ),
    );
  }
}
