<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { useField } from "vee-validate";

export default defineComponent({
  name: "AppFormField",
  emits: ["update:modelValue", "update:model-value"],
  props: {
    modelValue: {
      required: false,
    },
    name: {
      required: true,
      type: String,
    },
    placeholder: {
      default: "",
      type: String,
    },
    rules: {
      // veeValidate compatible string rules
      required: true,
      type: String,
    },
    type: {
      // veeValidate compatible string rules
      required: true,
      type: String as PropType<
        "text" | "number" | "email" | "radio" | "checkbox" | "password"
      >,
    },
    cssClass: {
      //css string classes
      required: false,
      type: String as PropType<string | any>,
      default:
        "appearance-none border-2 border-[#919eab3d] bg-white outline-0 focus:border-[#ACB7EC] transition-colors",
    },
    invalidValueCssClass: {
      //css string classes
      required: false,
      default: "invalid",
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      currentValue: this.$props.modelValue,
      field: useField(this.$props.name, this.$props.rules),
    };
  },
  watch: {
    "$props.modelValue": function () {
      if (this.$props.modelValue) {
        this.field.validate();
      } else {
        this.field.resetField();
      }
      this.currentValue = this.$props.modelValue;
    },
    currentValue() {
      this.field.setValue(this.currentValue);
      this.$emit("update:modelValue", this.currentValue);
    },
    "field.errors": function () {
      const el = this.$el as HTMLElement;
      if (!this.field.meta.valid && this.field.meta.touched) {
        el.classList.add(this.$props.invalidValueCssClass);
      } else {
        el.classList.remove(this.$props.invalidValueCssClass);
      }
    },
  },
  methods: {
    onBlur(e: Event) {
      this.field.handleBlur(e, true);
      // this.$emit("blur", e);
    },
  },
  unmounted() {
    if (import.meta.env.NODE_ENV === "development") {
      console.warn(
        "::FocusableFormField:: component is unmounted and cannot be focused anymore",
      );
    }
  },
  mounted() {
    this.field.setValue(this.currentValue);
  },
});
</script>

<template>
  <input
    :type="type"
    :name="name"
    v-model="currentValue"
    :class="cssClass"
    :placeholder="placeholder"
    :disabled="disabled"
    @change="field.handleChange"
    @blur="onBlur"
  />
</template>

<style scoped>
.invalid {
  border: 2px solid red;
}

input[type="radio"] {
  /* Remove default styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Set outer ring size and color */
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 50%;
  border: 3px solid #acb7ec;
  cursor: pointer;
}

/* Set inner dot color */
input[type="radio"]:after {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  margin: 3px;
  border-radius: 50%;
  background-color: white;
}

/* Change inner dot color on checked state */
input[type="radio"]:checked:after {
  background-color: #acb7ec;
}
</style>
