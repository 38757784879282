import type ApiResponse from "../models/api/response";
import { useUserStore } from "../stores/user";
import { i18n } from "../utils/i18n";

interface StringCondition {
  f: string;
  v: string;
  op: "eq" | "ei" | "ct" | "ci";
}

interface NumberCondition {
  f: string;
  v: number;
  op: "eq" | "gt" | "lt" | "ge" | "le";
}

interface BooleanCondition {
  f: string;
  v: boolean;
  op: "eq" | "ne";
}

type Condition = StringCondition | NumberCondition | BooleanCondition;

interface Query {
  cond: Condition[];
  op: "or" | "and";
}

//new Api().get("", "", { cond: [], op: "and" }, { id: 1 });
class Api {
  public get(
    url: string,
    method: string,
    filter: Query | null = null,
    query: { [key: string]: string } = {},
  ) {
    let queryString = "";
    if (filter) {
      query["filter"] = JSON.stringify(filter);
    }

    const queryFields = Object.keys(query);
    for (let i = 0; i < queryFields.length; ++i) {
      if (i == 0) {
        queryString += `?${queryFields[i]}=${encodeURIComponent(
          query[queryFields[i]],
        )}`;
      } else {
        queryString += `&${queryFields[i]}=${encodeURIComponent(
          query[queryFields[i]],
        )}`;
      }
    }

    return fetch(url + method + queryString, {
      method: "get",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + this.getToken(),
      },
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
  }

  public post(url: string, method: string, data: any) {
    return fetch(url + method, {
      method: "post",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + this.getToken(),
      },
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
  }

  public delete(url: string, method: string) {
    return fetch(url + method, {
      method: "delete",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + this.getToken(),
      },
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
  }

  public put(url: string, method: string, data: any) {
    return fetch(url + method, {
      method: "put",
      mode: "cors",
      headers: {
        "Content-type": "application/json",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Authorization: "Bearer " + this.getToken(),
      },
      cache: "no-cache",
      credentials: "same-origin",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
  }

  protected getToken() {
    const store = useUserStore();
    return store.getToken();
  }
}

export async function checkApiResponseError<T>(response: Response) {
  if (!response.ok && !isJsonResponse(response)) {
    throw new Error(
      `Cannot reach server (${response.statusText} ${response.status})`,
    );
  }

  try {
    const data: ApiResponse<T> = await response.json();
    if (!data.success || !response.ok) {
      throw new Error(
        data.message || data.detail || data.title || "GenericError",
      );
    }
    return data;
  } catch (e) {
    console.error(e);
    throw new Error(i18n.global.t((e as Error).message));
  }
}

export async function parseApiResponse<T>(response: Response) {
  const apiResponse = await checkApiResponseError<T>(response);
  return apiResponse.payload;
}

export function isJsonResponse(response: Response) {
  return response.headers.get("Content-Type")?.includes("application/json");
}

export default Api;
