<script lang="ts">
import { defineComponent } from "vue";
import ButtonComponent from "../Utils/ButtonComponent.vue";
export default defineComponent({
  name: "CookieBannerComponent",
  data() {
    return {
      acceptedTime: localStorage.getItem("acceptedCookiesTime"),
      rejectedTime: localStorage.getItem("rejectedCookiesTime"),
    };
  },
  methods: {
    onAccept() {
      this.acceptedTime = new Date().toJSON();
      localStorage.setItem("acceptedCookiesTime", this.acceptedTime);
    },
    onReject() {
      this.rejectedTime = new Date().toJSON();
      localStorage.setItem("rejectedCookiesTime", this.rejectedTime);
    },
  },
  components: { ButtonComponent },
});
</script>

<template>
  <div
    class="fixed flex flex-col lg:flex-row justify-between items-center w-full text-white p-5 bottom-0 bg-black z-50"
    v-if="!acceptedTime && !rejectedTime"
  >
    <div>
      <p class="text-xl ml-0 lg:ml-64 mb-3 font-semibold drop-shadow-md">
        {{ $t("CookieBannerTitle") }}
      </p>
      <p class="ml-0 lg:ml-64 mb-2 drop-shadow-md">
        {{ $t("CookieBannerContent") }}
      </p>
      <a
        href="https://www.limolane.com/cookie-policy/"
        target="_blank"
        class="ml-0 lg:ml-64 underline drop-shadow-md"
        >{{ $t("CookieBannerPolicyLinkText") }}</a
      >
    </div>
    <div class="flex flex-row items-center mr-0 lg:mr-64 gap-6 mt-3">
      <ButtonComponent
        :label="$t('ButtonReject')"
        @on-click="onReject()"
        class="self-end"
        type-style="primary-alternative"
      />

      <ButtonComponent :label="$t('ButtonAccept')" @on-click="onAccept()" />
    </div>
  </div>
</template>

<style scoped></style>
