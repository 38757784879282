import type { ScontoCoupon } from "../models/promo/coupon";
import type { CreditoRequest } from "../models/promo/credit";
import type { Voucher, VoucherRequest } from "../models/promo/voucher";
import type { Credito, CreditoV2 } from "../models/user/user";
import type { MilleMigliaSettingsModel } from "../models/promo/millemiglia";
import type { FlyingBlueSettingsModel } from "../models/promo/flyingblue";
import Api, { parseApiResponse } from "./Api";
import type { VolareSettingsModel } from "../models/promo/volare";

export default class PromoApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + "promo/";
  creditApiUrl = import.meta.env.VITE_API_URL + "v2/clienti/credito/";
  milleMigliaApiUrl = import.meta.env.VITE_API_URL + "millemiglia/";
  flyingBlueApiUrl = import.meta.env.VITE_API_URL + "flyingblue/";
  volareApiUrl = import.meta.env.VITE_API_URL + "volare/";

  async getCustomerCredit() {
    const response = await this.get(this.baseApiUrl, "getCredito");
    const stringPayload = await parseApiResponse<string>(response);
    return JSON.parse(stringPayload) as Credito;
  }

  async burnVoucher(voucherReq: VoucherRequest) {
    const stringData = await parseApiResponse<string>(
      await this.post(this.baseApiUrl, "credit/burnvoucher", voucherReq),
    );
    return JSON.parse(stringData) as Voucher;
  }

  async removeCredit(addCreditReq: CreditoRequest) {
    const stringData = await parseApiResponse<string>(
      await this.post(this.baseApiUrl, "credit/remove", addCreditReq),
    );
    return JSON.parse(stringData) as Credito;
  }

  async getUsedCredits(idcliente: number, idazienda: number) {
    return parseApiResponse<Array<CreditoV2>>(
      await this.get(
        this.creditApiUrl,
        `CreditoByClienteAzienda?idcliente=${idcliente}&idazienda=${idazienda}`,
      ),
    );
  }

  async getDiscountCouponById(id: number) {
    return parseApiResponse<ScontoCoupon>(
      await this.get(this.baseApiUrl, `coupon/${id}`),
    );
  }

  async getDiscountCouponByCode(code: string) {
    return parseApiResponse<ScontoCoupon>(
      await this.get(this.baseApiUrl, `coupon/search?code=${code}`),
    );
  }

  async insertMilleMigliaCode(req: {
    IDCliente: number;
    IDAzienda: number;
    MilleMigliaCode: string;
  }) {
    return parseApiResponse<void>(
      await this.post(this.milleMigliaApiUrl, "insertcode", req),
    );
  }

  async getMilleMigliaSettings(lang: string) {
    const data = await parseApiResponse<string>(
      await this.get(this.milleMigliaApiUrl, "settings?language=" + lang),
    );
    return JSON.parse(data) as MilleMigliaSettingsModel;
  }

  async insertFlyingBlueCode(req: {
    IDCliente: number;
    IDAzienda: number;
    FBCode: string;
  }) {
    return parseApiResponse<void>(
      await this.post(this.flyingBlueApiUrl, "insertcode", req),
    );
  }

  async getFlyingBlueSettings(lang: string) {
    const data = await parseApiResponse<string>(
      await this.get(this.flyingBlueApiUrl, "settings?language=" + lang),
    );
    return JSON.parse(data) as FlyingBlueSettingsModel;
  }

  async insertVolareCode(req: {
    IDCliente: number;
    IDAzienda: number;
    FBCode: string;
  }) {
    return parseApiResponse<void>(
      await this.post(this.volareApiUrl, "insertcode", req),
    );
  }

  async getVolareSettings(lang: string) {
    const data = await parseApiResponse<string>(
      await this.get(this.volareApiUrl, "settings?language=" + lang),
    );
    return JSON.parse(data) as VolareSettingsModel;
  }
}
