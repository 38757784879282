<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "AppSpinner",
  props: {
    show: Boolean,
    cssClass: {
      type: String,
      default:
        "spinner-backdrop fixed inset-0 z-50 items-center justify-center bg-transparent backdrop-filter backdrop-blur-[1px] backdrop-brightness-90",
    },
    size: {
      type: String as PropType<"app-loader" | "small">,
      default: "app-loader",
    },
  },
});
</script>

<template>
  <div :class="cssClass" v-if="show">
    <div :class="['lds-ring', size, cssClass]">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<style scoped>
.spinner-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.402);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ring {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(4, 3, 3);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #acb7ec transparent transparent transparent;
}

.lds-ring.app-loader div {
  width: 64px;
  height: 64px;
  border-width: 8px;
}

.lds-ring.small div {
  width: 20px;
  height: 20px;
  border-width: 4px;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
