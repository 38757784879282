import { useToast } from "vue-toastification";
import { i18n } from "../../utils/i18n";
import { useUiStore } from "../ui";

function UiStorePlugin() {
  const uiStore = useUiStore();
  return {
    uiStore,
    toast: useToast(),
    i18n: i18n,
  };
}

export default UiStorePlugin;
