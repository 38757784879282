<script lang="ts">
import type { Favorite } from "@/models/customer/favoriteAddress";
import {
  ValuteListini,
  type PreventivoModel,
  type TipoMezzo,
  type Viaggio,
} from "@/models/trip/trip";
import { getVattedPrice, priceRound } from "@/models/utils";
import { useTripStore } from "@/stores/trip";
import { useUiStore } from "@/stores/ui";
import { useUserStore } from "@/stores/user";
import { dateToDateTime } from "@/utils/dateToDateTime";
import { defineComponent } from "vue";

interface RoutePrice {
  destinazione: string;
  tratte: {
    mezzo: {
      id: string;
      title: string;
      description: string;
      maxSeat: number;
      maxBagagli: number;
      img: string;
    };
    costo: number;
  }[];
}

export default defineComponent({
  name: "Wallpaper",
  setup() {
    return {
      uiStore: useUiStore(),
      userStore: useUserStore(),
      tripStore: useTripStore(),
    };
  },
  data() {
    return {
      isShow: true,
      isHidden: false,
      showImage: true,
      // favorites: [],
      tipo_mezzo: [
        {
          id: "e.png",
          title: "Standard Sedan",
          description: "Mercedes E Class or similar",
          maxSeat: 3,
          maxBagagli: 3,
          img: "standard-sedan.png",
        },
        {
          id: "s.png",
          title: "Luxury Sedan",
          description: "Mercedes S Class or similar",
          maxSeat: 3,
          maxBagagli: 3,
          img: "luxury-sedan.png",
        },
        {
          id: "t.png",
          title: "Luxury Green",
          description: "Tesla Model",
          maxSeat: 3,
          maxBagagli: 3,
          img: "luxury-green.png",
        },
        {
          id: "v.png",
          title: "Luxury Van",
          description: "Mercedes V Class or similar",
          maxSeat: 7,
          maxBagagli: 7,
          img: "luxury-van.png",
        },
      ],
      preventivo_tratte: [] as RoutePrice[],
    };
  },
  computed: {
    isSessionTO() {
      if (this.userStore.isHotel) {
        if (this.uiStore.wallPaper.show) {
          this.show();
        } else {
          this.hide();
        }
      }
      return this.uiStore.wallPaper.show;
    },
    wallpaperLogo() {
      return this.userStore.currentCustomerConfig.tariffario_Pdf;
    },
    hotelLogo() {
      return this.userStore.currentCustomerConfig.logo_intestazione;
    },
    acceptSlavesCode() {
      return this.userStore.currentCustomerConfig.autoAcceptSlavesCode;
    },
    srcQrCodeAcceptSlaves() {
      if (this.acceptSlavesCode) {
        return (
          "https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=" +
          this.acceptSlavesCode
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    preventivo_tratte_filterd(mezzo: String) {
      const toReturn: any[] = [];
      var x = 1;
      this.preventivo_tratte.forEach(function (prev) {
        var tratte = prev.tratte;
        var tratta = tratte.find((t) => t.mezzo.id == mezzo);
        if (tratta !== null && tratta !== undefined) {
          toReturn.push({
            id: x,
            costo: tratta.costo,
          });
        } else {
          toReturn.push({
            id: x,
            costo: undefined,
          });
        }
        x++;
      });
      return toReturn;
    },
    show() {
      this.isShow = true;
      this.isHidden = false;
    },
    hide() {
      this.isShow = false;
      setTimeout(() => {
        this.uiStore.wallPaper.show = false;
        this.isHidden = true;
      }, 300);
    },
  },
  async mounted() {
    const blacklist: String[] = this.userStore.customCustomerConfig
      .filter(function (item) {
        return item.nome == "Tipo Mezzo" && item.bloccato;
      })
      .map(function (item) {
        var id = item.valore;
        if (id == "STANDARD SEDAN") {
          return "e.png";
        } else if (id == "LUXURY SEDAN") {
          return "s.png";
        } else if (id == "LUXURY VAN") {
          return "v.png";
        } else if (id == "LUXURY BUS") {
          return "b.png";
        } else if (id == "LUXURY GREEN") {
          return "t.png";
        } else {
          return "x.png";
        }
      });

    this.tipo_mezzo = this.tipo_mezzo.filter(function (item) {
      return !blacklist.includes(item.id);
    });

    var scontoMaster;
    if (this.userStore.isSlave) {
      scontoMaster = this.userStore.masterConfig?.sconto;
    }

    const favorites = this.userStore.favoriteAddresses;
    var origin = favorites.find((f) => f.isOrigin);
    const destinations: Favorite[] = [];
    var shouldSkip = false;
    favorites.forEach(function (favorite) {
      if (shouldSkip) {
        return;
      }
      if (!favorite.isOrigin) {
        destinations.push(favorite);
        if (destinations.length === 4) {
          shouldSkip = true;
          return;
        }
      }
    });
    if (
      origin === null ||
      origin == undefined ||
      destinations == null ||
      destinations == undefined ||
      destinations.length <= 0
    ) {
      this.showImage = true;
    } else {
      this.showImage = false;
      try {
        for (let x = 0; x < destinations.length; x++) {
          const destination = destinations[x];
          const tratta = [];
          for (var c = 0; c < this.tipo_mezzo.length; c++) {
            const now = new Date();
            const minBookingTime =
              this.userStore.currentCustomerConfig.tempo_inserimento || 480;
            now.setMinutes(now.getMinutes() + minBookingTime);
            const isoDate = `${now.getFullYear()}-${(now.getMonth() + 1)
              .toString()
              .padStart(2, "0")}-${now
              .getDate()
              .toString()
              .padStart(2, "0")}T${now
              .getHours()
              .toString()
              .padStart(2, "0")}:${now
              .getMinutes()
              .toString()
              .padStart(2, "0")}:00Z`;
            const tipoMezzo = this.tipo_mezzo[c];
            const preventivi = await this.tripStore.tripApi.calculateTrip({
              aziendaid: this.userStore.currentIdAzienda!,
              clienteid: this.userStore.currentIdCliente!,
              currency: ValuteListini[this.userStore.currentUserCurrency],
              nazionePickup: "IT",
              nazioneDropOff: "IT",
              os: "web",
              preventivo: [
                {
                  baggage: "0",
                  luggage: "0",
                  quantity: 1,
                  currencyStart:
                    ValuteListini[this.userStore.currentUserCurrency],
                  currencyEnd:
                    ValuteListini[this.userStore.currentUserCurrency],
                  exchangeRate:
                    this.userStore.eurExchangeRate[
                      this.userStore.currentUserCurrency
                    ],
                  tipomezzo: {
                    id: tipoMezzo.id,
                  } as Partial<TipoMezzo> as TipoMezzo,
                } as Partial<PreventivoModel> as PreventivoModel,
              ],
              viaggio: {
                iD_Azienda: this.userStore.currentIdAzienda,
                data: dateToDateTime(new Date(isoDate)),
                destinazione: destination.address,
                partenza: origin.address,
                puntoDestinazioneLat: Number(origin.lat),
                puntoDestinazioneLong: Number(origin.lng),
                puntoPartenzaLat: Number(destination.lat),
                puntoPartenzaLong: Number(destination.lng),
                td: 57,
                tot_km: 0,
                tot_Minuti: 0,
              } as Partial<Viaggio> as Viaggio,
              token: "",
            });
            const prev = preventivi[0];
            if (
              !prev ||
              !prev.Legacy ||
              prev.Legacy?.descrittore == undefined ||
              prev.Legacy?.descrittore == null ||
              prev.Legacy?.descrittore.TipoServizio === "Km"
            ) {
              tratta.push({
                mezzo: tipoMezzo,
                costo: -1,
              });
            } else {
              let totale = prev.totale;
              // if (prev.Legacy?.descrittore.TariffaImponibile) {
              totale = priceRound(
                getVattedPrice(prev.totale, prev.AliquotaIva),
                0,
              );
              // }
              if (!prev.Legacy?.LockedPrices) {
                if (prev.Legacy?.descrittore.ScontoPerc) {
                  const sconto =
                    (totale * prev.Legacy?.descrittore.ScontoPerc) / 100;
                  totale = Number((totale - sconto).toFixed(2));
                }
                if (
                  this.userStore.currentCustomerConfig.sconto &&
                  this.userStore.currentCustomerConfig.sconto != 0
                ) {
                  const scontoC =
                    (totale * this.userStore.currentCustomerConfig.sconto) /
                    100;
                  totale = Number((totale - scontoC).toFixed(2));
                } else {
                  if (scontoMaster && scontoMaster > 0) {
                    const scontoM = (totale * scontoMaster) / 100;
                    totale = Number((totale - scontoM).toFixed(2));
                  }
                }
              }

              const t1 = totale / 5;
              const t2 = Number(Math.ceil(t1).toFixed(0));
              totale = t2 * 5;

              tratta.push({
                mezzo: tipoMezzo,
                costo: Number(totale.toFixed(2)),
              });
            }
          }
          this.preventivo_tratte.push({
            destinazione: destination.name,
            tratte: tratta,
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  },
});
</script>

<template>
  <div
    class="wrapper no_header_mod"
    v-bind:class="{ in: isSessionTO, hidden: isHidden }"
  >
    <div class="wallpaper">
      <img
        v-if="showImage"
        v-bind:src="wallpaperLogo ? wallpaperLogo : '/imgs/wallpaper.jpg'"
        v-on:click="hide()"
        class="imgwallpaper"
      />

      <div v-else v-on:click="hide()" class="imgwallpaper_dynamic">
        <div class="limolane_logo_container flex flex-col content-center">
          <img
            src="@/assets/imgs/Logo-access.png"
            class="h-[60%] object-contain"
          />
        </div>
        <table class="table_tratte">
          <tr>
            <th>
              <div class="h-20">
                <img
                  class="h-20 object-contain"
                  v-bind:src="hotelLogo ? hotelLogo : '/i/header_logo.png'"
                  style="object-fit: contain"
                />
              </div>
            </th>
            <th></th>
            <th></th>
            <th
              v-for="preventivo in preventivo_tratte"
              :key="preventivo.destinazione"
              class="price_column"
            >
              <div
                style="
                  width: 10em;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  margin: 1em;
                  font-size: 0.55rem;
                "
              >
                <span> {{ preventivo.destinazione }} </span>
              </div>
            </th>
          </tr>
          <tr
            v-for="(mezzo, i) in tipo_mezzo"
            v-bind:key="mezzo.id"
            :class="{ '': i % 2 === 0, row_even: i % 2 !== 0 }"
          >
            <td class="mezzo_column">
              <div
                class="mezzo_column_text"
                v-bind:style="mezzo.id === 't.png' ? 'color: #70e000;' : ''"
              >
                <strong>{{ mezzo.title }}</strong>
                <span>{{ mezzo.description }}</span>
              </div>
            </td>
            <td>
              <img
                class="h-20 w-40 object-contain"
                v-bind:src="'/imgs/cars/' + mezzo.img"
                v-bind:alt="mezzo.title"
              />
            </td>
            <td>
              <div
                style="
                  width: 10rem;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                "
              >
                <div style="display: flex; align-items: center; color: white">
                  <img src="@/assets/icons/icon2.png" alt="" class="h-5 w-5" />
                  <span
                    style="
                      font-family: Montserrat, sans-serif;
                      color: white;
                      white-space: nowrap;
                      margin-left: 0.5rem;
                      font-size: 0.7rem;
                    "
                    >Max {{ mezzo.maxBagagli }}</span
                  >
                </div>
                <div style="display: flex; align-items: center; color: white">
                  <img src="@/assets/icons/icon1.png" alt="" class="h-5 w-5" />
                  <span
                    style="
                      font-family: Montserrat, sans-serif;
                      color: white;
                      white-space: nowrap;
                      margin-left: 0.5rem;
                      font-size: 0.7rem;
                    "
                    >Max {{ mezzo.maxSeat }}</span
                  >
                </div>
              </div>
            </td>
            <td
              v-for="preventivo in preventivo_tratte_filterd(mezzo.id)"
              v-bind:key="preventivo.id"
              class="price_column"
            >
              <span v-if="preventivo.costo !== -1">
                € {{ preventivo.costo.toFixed(2) }}
              </span>
              <span v-else> RECEPTION </span>
            </td>
          </tr>
        </table>
        <div class="w-full flex items-center text-white mt-3">
          <img
            src="/imgs/qr_code.png?version=1"
            class="self-start ml-auto h-[7rem] w-[7rem]"
          />
          <div class="flex flex-col items-center mx-5">
            <span>Scan the <strong>QR Code</strong></span>
            <span>Download <strong>app and register!</strong></span>
          </div>
          <img
            src="/imgs/app_preview.png?version=1"
            class="self-center h-[7rem] w-[8rem]"
          />
          <div class="flex flex-col items-center mx-5">
            <span>Scan the <strong>QR Code</strong> and discover rates!</span>
            <span
              >Or use following code <strong>{{ acceptSlavesCode }}</strong
              >!</span
            >
          </div>
          <img
            :src="srcQrCodeAcceptSlaves"
            class="self-end h-[7rem] w-[7rem] mr-auto"
          />
        </div>
      </div>
    </div>
    <footer class="footer"></footer>
  </div>
</template>

<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  opacity: 0;
  background-color: #ffffff;
  z-index: 9999999999999;
  animation: fadeOut ease 0.3s;
  -webkit-animation: fadeOut ease 0.3s;
  -moz-animation: fadeOut ease 0.3s;
  -o-animation: fadeOut ease 0.3s;
  -ms-animation: fadeOut ease 0.3s;
  font-size: 8px;
}
.wrapper.in {
  opacity: 1;
  animation: none;
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  -ms-animation: none;
}
.wrapper.hidden {
  display: none !important;
}

.wallpaper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.imgwallpaper {
  object-fit: cover;
  height: 100%;
}

.imgwallpaper_dynamic {
  object-fit: contain;
  height: 100%;
  background-color: #292929;
}

.mezzo_img_wrap {
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.mezzo_column {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 0.6rem;
}

.mezzo_column_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 25px;
  font-family: "Montserrat", sans-serif;

  color: white;
}

.hotel_logo img {
  height: 7rem;
}

.price_column {
  padding: 0 5px;
}

.price_column span {
  font-family: "Montserrat", sans-serif;
  /*  */
  color: white;
  font-size: 0.9rem;
}

.row_even {
  background-color: #474747;
}

.limolane_logo_container {
  padding-top: 5px;
  height: 10%;
}

.table_tratte {
  margin: 2px auto;
  table-layout: fixed;
}

.download_app_container {
  width: 80%;
  margin: 0 auto;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle !important;
}

.download_app_container strong {
  font-family: "Montserrat", sans-serif;

  vertical-align: middle !important;
  color: white;
}

.download_app_container span {
  font-family: "Montserrat", sans-serif;

  color: white;
  vertical-align: middle !important;
}
/* ==========================================================================
     ANIMATION: fadeOut
     ========================================================================== */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
