import { useToast } from "vue-toastification";
import { i18n } from "./i18n";

export async function UploadFileAsync(
  url: string,
  method: string,
  file: File,
  token: string,
): Promise<string> {
  const toast = useToast();
  try {
    const response = await uploadSingleFile(url, method, file, token);
    if (response?.status !== 200) {
      throw new Error(response.statusText);
    }
    const resp = await response.json();
    return resp.payload;
  } catch (e) {
    console.error(e);
    toast.error(`${i18n.global.t("Request_failed_message")}`);
    throw e;
  }
}

export async function uploadSingleFile(
  url: string,
  method: string,
  file: Blob,
  token: string,
) {
  var formData = new FormData();
  formData.append("file", file);
  return fetch(url + method, {
    method: "post",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
    },
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: formData,
  });
}

export async function uploadMultiFiles(
  url: string,
  method: string,
  fileList: File[],
  token: string,
) {
  var formData = new FormData();

  let filesArray = Array.from(fileList);
  filesArray.forEach((f) => {
    formData.append("files", f);
  });
  formData.append(`descrizione`, "");
  return fetch(url + method, {
    method: "post",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
    },
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: formData,
  });
}

export async function UploadMultiFilesAsync(
  url: string,
  method: string,
  fileList: File[],
  token: string,
): Promise<string> {
  const toast = useToast();
  try {
    const response = await uploadMultiFiles(url, method, fileList, token);
    if (response?.status !== 200) {
      throw new Error(response.statusText);
    }
    const resp = await response.json();
    return resp.payload;
  } catch (e) {
    console.error(e);
    toast.error(`${i18n.global.t("Request_failed_message")}`);
    throw e;
  }
}
