﻿<script lang="ts">
import { defineComponent } from "vue";
import { useTripStore } from "../../../stores/trip";
import { useUiStore } from "../../../stores/ui";
import { i18n } from "../../../utils/i18n";

export default defineComponent({
  name: "ToastTripSuccess",
  setup() {
    return {
      ...i18n.global, // needed because usei18n or $t are not injected, this component is created in a different context by VueToastification
      tripStore: useTripStore(),
      uiStore: useUiStore(),
    };
  },
  methods: {
    handleClickOutside(event: Event) {
      if (!(this.$el as HTMLElement).contains(event.target as Node)) {
        this.uiStore.toast.dismiss("book_return");
      }
    },
  },
  created() {
    window.addEventListener("click", this.handleClickOutside);
  },
  /* Per evitare Memory leaks uso una sorta di destroy */
  beforeUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  },
});
</script>

<template>
  <div class="flex flex-col lg:flex-row w-full">
    <div class="mb-4 lg:mb-0">
      <div class="flex items-center pb-1">
        <img class="h-6 mr-2" src="@/assets/icons/ic_check.png" alt="" />
        <h1 class="text-[#2F6006] font-extrabold">
          {{ t("ToastTripSuccessTitle") }}
        </h1>
      </div>
      <p class="text-[#2F6006] font-medium text-sm ml-8">
        {{ t("ToastTripSuccessBody") }}
      </p>
      <p class="text-[#2F6006] font-medium text-sm ml-8 pt-2">
        {{ t("ToastTripSuccessCTA") }}
      </p>
    </div>

    <div class="flex my-auto">
      <button
        class="min-w-max text-[#2F6006] text-sm border-2 border-[#2e600680] ml-6 px-3.5 py-1.5 active:scale-95 transition-transform"
        @click="tripStore.setupTripFromLastSent()"
      >
        {{ t("ButtonToastTripSuccessCTA") }}
      </button>
    </div>
  </div>
</template>

<style scoped></style>
