﻿<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { i18n } from "../../utils/i18n";

export type DropdownComponentOption = {
  text: string;
  value: any;
};

export type DropdownComponentDataType = {
  selectedOption: any;
  isOpen: boolean;
  searchValue: string;
  isValueSelected: boolean;
  touched: boolean;
};

export interface DropdownFields {
  selectedText: string; // used to show a shorter placeholder of the selcted value
  dropdownText: string; // used to show the text in the dropdown
  value: string;
}

/**
 * Works as Syncfusion dropdown
 * You get an array of <any> options and define in the "fields" prop the text key to show and the value key
 * You can use the v-model, an selectedOption callback and
 * an advaced filterCallback option to execute stuff at a higher level and refresh options from the parent (eg call an API with filtering)
 */
export default defineComponent({
  name: "DropdownComponent",
  emits: ["update:modelValue", "selectedOption", "blur", "onPaste"],
  props: {
    options: {
      type: Object as PropType<Array<any>>,
      required: true,
    },
    enableFiltering: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: i18n.global.t("DropdownDefaultPlaceholder"),
    },
    clearFunction: {
      type: Boolean,
      default: true,
    },
    /* Make the dropdown open on Top of the Field(button in this case) */
    isOpeningTop: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      required: false,
      type: [String, Number, Object],
    },
    isDarkColor: { type: Boolean, default: true },
    isDarkAltColor: {
      type: Boolean,
      default: false,
    },
    isLightColor: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object as PropType<DropdownFields>,
      default: function () {
        return {
          selectedText: "selectedText",
          dropdownText: "dropdownText",
          value: "value",
        };
      },
    },
    filterCallback: {
      type: Function,
      required: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isLoadingOptions: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCustomInput: {
      type: Boolean,
      default: false,
    },
    cssInputClass: {
      type: String,
      default: "",
    },
    dropdownListCssClass: {
      type: String,
      default: "w-full",
    },
  },
  data(): DropdownComponentDataType {
    return {
      isOpen: false,
      selectedOption: null,
      searchValue: "",
      isValueSelected: false,
      touched: false,
    };
  },
  computed: {
    computedOptions(): any[] {
      if (!this.enableFiltering && !this.filterCallback) {
        return this.options;
      }

      if (this.filterCallback && this.enableFiltering) {
        return this.options;
      }

      const filterText = this.searchValue.toLowerCase();
      return (
        (!this.touched && this.options) ||
        this.options.filter((option) =>
          option[this.fields.dropdownText]
            ?.toString()
            .toLowerCase()
            .includes(filterText),
        )
      );
    },
  },
  methods: {
    selectOption(option: any) {
      this.selectedOption = option;
      this.isOpen = false;
      this.searchValue = option[this.fields.selectedText];
      this.isValueSelected = true;
      if (!this.disabled) {
        this.emitEvents(option[this.fields.value]);
      }
    },
    reset() {
      this.selectedOption = null;
      this.searchValue = "";
      this.isValueSelected = false;
    },
    clearOption() {
      if (this.clearFunction) {
        this.reset();
        if (!this.disabled) {
          this.emitEvents(null);
        }
      }
    },
    handleClickOutside(event: MouseEvent) {
      if (
        this.isOpen &&
        !(this.$el as HTMLElement).contains(event.target as Node)
      ) {
        this.onCloseDropdown();
      }
    },
    setSelectedOption() {
      if (this.modelValue || this.modelValue === 0) {
        this.selectedOption = this.options.find(
          (e) => e[this.fields.value] == this.modelValue,
        );
        if (this.selectedOption) {
          this.searchValue = this.selectedOption[this.fields.selectedText];
        } else if (this.allowCustomInput && !this.selectedOption) {
          this.searchValue = this.modelValue as string;
        }
        this.isValueSelected = true;
      } else {
        this.reset();
      }
    },
    emitEvents(value: any) {
      this.$emit("selectedOption", value);
      this.$emit("update:modelValue", value);
      this.touched = false; // false only when gives an output
    },
    checkAndClearInput() {
      if (!this.allowCustomInput) {
        this.searchValue =
          (this.selectedOption &&
            this.selectedOption[this.fields.selectedText]) ||
          "";
        this.touched = false;
      } else {
        this.searchValue = this.modelValue as string;
      }
      this.$emit("blur");
    },
    onCloseDropdown() {
      console.log("onCLose");
      this.isOpen = false;
      this.checkAndClearInput();
    },
    onOpenDropdown() {
      this.isOpen = true;
    },
    setSearchValueAsModelValue() {
      this.searchValue = this.modelValue as string;
    },
  },
  watch: {
    searchValue: function () {
      if (
        this.selectedOption &&
        this.searchValue === this.selectedOption[this.fields.selectedText]
      ) {
        return;
      }
      if (this.filterCallback && this.enableFiltering) {
        this.filterCallback(this.searchValue);
      }
      if (this.allowCustomInput) {
        this.emitEvents(this.searchValue);
      }
    },
    modelValue: function () {
      this.setSelectedOption();
    },
    options: function () {
      this.setSelectedOption();
    },
  },
  created() {
    window.addEventListener("click", this.handleClickOutside);
  },
  /* Per evitare Memory leaks uso una sorta di destroy */
  beforeUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  },
  mounted() {
    this.setSelectedOption();
  },
});
</script>

<template>
  <div class="relative group">
    <div>
      <label
        class="label-dropdown px-0.5 text-[#919EAB] text-sm absolute -top-2.5 left-2 group-focus-within:text-[#ACB7EC] transition-colors truncate z-10"
        :class="[
          {
            'bg-limolane-bg-blue': isDarkColor || isDarkAltColor,
            'bg-white': isLightColor,
          },
          { 'text-[#FF572D]': isInvalid },
        ]"
        v-if="label"
        >{{ label }}</label
      >
      <slot
        name="input"
        v-bind="{
          selectOption,
          fields,
          onCloseDropdown,
          selectedOption,
          onOpenDropdown,
        }"
      >
        <input
          v-model="searchValue"
          :placeholder="placeholder"
          :disabled="disabled"
          @focus="isOpen = !disabled"
          @keydown.stop="touched = true"
          @keyup.ctrl.v.exact="$emit('onPaste', searchValue)"
          type="text"
          class="filter-dropdown w-full p-2 border-2 border-[#919eab3d] placeholder:text-[#919EAB] outline-0 focus:border-[#ACB7EC] transition-colors disabled:opacity-75 disabled:cursor-not-allowed"
          :class="[
            {
              'bg-limolane-bg-blue': isDarkColor || isDarkAltColor,
              'bg-white': isLightColor,
            },
            { 'border-[#FF572D]': isInvalid },
            {
              'text-white': isDarkColor || isDarkAltColor,
              'text-limolane-bg-lightblue': isLightColor,
            },
            cssInputClass,
          ]"
        />
      </slot>
      <button
        type="button"
        class="clear-button-dropdown absolute top-2.5 right-5 h-6 w-6 flex items-center justify-center"
        v-if="
          !disabled &&
          clearFunction &&
          ((!allowCustomInput && isValueSelected && selectedOption) ||
            (allowCustomInput && searchValue))
        "
        @click="clearOption"
      >
        <!-- <img src="../../assets/icons/ic_remove.png" alt="" /> -->
        <div
          class="h-5 w-5 flex items-center justify-center hover:bg-limolane-primary-alpha rounded-full transition-colors"
        >
          <fa-icon
            icon="fa-solid fa-circle-xmark"
            :class="{
              'text-white': isDarkColor || isDarkAltColor,
              'text-limolane-bg-lightblue': isLightColor,
            }"
          />
        </div>
      </button>
      <svg
        v-if="!disabled"
        :class="{
          'transform rotate-180 transition-transform': isOpen,
        }"
        class="svg-dropdown w-4 h-4 absolute right-1 top-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="white"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>

    <ul
      v-if="isOpen && computedOptions.length > 0"
      class="absolute bottom mt-1 max-h-56 overflow-y-auto bg-limolane-bg-blue border-2 border-[#919eab3d] shadow-md z-[100]"
      :class="[
        {
          'bg-limolane-bg-blue': isDarkColor || isDarkAltColor,
          'bg-[#919eab]': isLightColor,
        },
        dropdownListCssClass,
      ]"
    >
      <li
        class="w-full flex items-center justify-center"
        v-if="isLoadingOptions"
      >
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </li>
      <li v-for="(option, code) in computedOptions" :key="code">
        <slot
          name="item"
          v-bind="{
            selectOption,
            option,
            fields,
            onCloseDropdown,
            selectedOption,
          }"
        >
          <button
            type="button"
            @click="selectOption(option)"
            class="dropdown-option block w-full px-3 py-1 h-12 text-left border-b border-[#919eab3d] truncate"
            :class="[
              {
                'bg-limolane-bg-blue text-white': isDarkColor || isDarkAltColor,
                'bg-white text-limolane-bg-blue ': isLightColor,
              },
            ]"
          >
            {{ option[fields.dropdownText] }}
          </button>
        </slot>
      </li>
      <li class="w-full" v-if="computedOptions.length === 0">
        <button
          type="button"
          disabled
          class="dropdown-option block w-full px-3 py-1 h-12 text-white text-left border-b border-[#919eab3d] truncate"
          :class="[
            {
              'bg-limolane-bg-blue': isDarkColor || isDarkAltColor,
              'bg-white': isLightColor,
            },
          ]"
        >
          {{ $t("NoRecordFound") }}
        </button>
      </li>
    </ul>
  </div>
</template>

<style>
ul {
  min-width: 16rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 3rem;
  height: 3rem;
  margin: 8px;
  border: 6px solid rgb(4, 3, 3);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #acb7ec transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropdown-option:hover {
  background-color: #919eab4f !important;
}
</style>
