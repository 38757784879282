﻿<script lang="ts">
import { defineComponent } from "vue";
import { useUserStore } from "../stores/user";
import { useUiStore } from "../stores/ui";
import { getBrowserLocale, getLocaleOnly, i18n } from "../utils/i18n";
import Dropdown from "./Utils/Dropdown.vue";
import countries from "../constants/countries";
import { LANG_ISO_CODE } from "../constants/lang_iso_code";
import AppDialog from "./Utils/AppDialog.vue";
import { Form as ValidatorForm } from "vee-validate";
import { useToast } from "vue-toastification";
import AppFormField from "./Utils/AppFormField.vue";
import { useTripStore } from "@/stores/trip";

export default defineComponent({
  name: "AppNavbar",
  setup() {
    return {
      uiStore: useUiStore(),
      userStore: useUserStore(),
      countries,
      getBrowserLocale,
      LANG_ISO_CODE,
    };
  },
  data() {
    return {
      isNavbarHamburgerOpen: false,
      isNavbarProfileOpen: false,
      languages: [
        {
          name: "IT",
          isoCode: "it-IT",
          image: countries.IT.image,
        },
        { name: "EN", isoCode: "en-GB", image: countries.GB.image },
        { name: "FR", isoCode: "fr-FR", image: countries.FR.image },
      ],
      showHotelPassword: false,
      hotelPassword: "",
    };
  },
  methods: {
    closeNavbar() {
      this.isNavbarHamburgerOpen = false;
    },
    handleClickOutside(event: MouseEvent) {
      if (
        this.isNavbarProfileOpen &&
        !(this.$refs.profileDropdown as HTMLElement).contains(
          event.target as Node,
        )
      ) {
        this.isNavbarProfileOpen = false;
        this.toggleClickListener();
      }
    },
    toggleClickListener() {
      if (this.isNavbarProfileOpen) {
        window.addEventListener("click", this.handleClickOutside);
      } else {
        window.removeEventListener("click", this.handleClickOutside);
      }
    },
    toggleNavbar() {
      this.isNavbarProfileOpen = !this.isNavbarProfileOpen;
      this.toggleClickListener();
    },
    async onChangeLanguage(isoCode: string) {
      this.uiStore.toggleLoading(true);
      this.userStore.currentCustomerConfig.notificationLanguage =
        getLocaleOnly(isoCode);
      await this.userStore.customerApi.updateCustomerConfiguration(
        this.userStore.currentCustomerConfig,
        this.userStore.currentIdAzienda!,
      );
      this.uiStore.toggleLoading(false);
      i18n.global.locale.value = isoCode;
      localStorage.setItem("favorite_language", isoCode);
      window.location.reload();
    },
    showHotelWallpaper() {
      this.uiStore.wallPaper.show = true;
      const tripStore = useTripStore();
      tripStore.reset();
      this.$router.replace("/");
    },
    onToggleMobileMenu() {
      if (this.userStore.isHotel && !this.isNavbarHamburgerOpen) {
        this.showHotelPassword = true;
        return;
      }
      this.isNavbarHamburgerOpen = !this.isNavbarHamburgerOpen;
    },
    onCheckHotelPassword() {
      if (this.hotelPassword == "reception24") {
        this.showHotelPassword = false;
        this.isNavbarHamburgerOpen = true;
        this.hotelPassword = "";
        return;
      }
      const toast = useToast();
      toast.error(this.$t("Error_incorrect_password"));
    },
    updateNavbarMargin() {
      const navbar = this.$refs.navbar as HTMLElement;
      const navbarContainer = this.$refs.navbarContainer as HTMLElement;
      navbarContainer.style.marginBottom = navbar.offsetHeight + "px";
    },
  },
  computed: {
    languagesOption() {
      return this.languages.map((item) => ({
        dropdownText: item.name,
        selectedText: item.name,
        value: item.isoCode,
        image: item.image,
      }));
    },
    customLogo() {
      return (
        this.userStore.currentCustomerConfig.logo_intestazione ||
        this.userStore.agency?.logo_intestazione ||
        "/imgs/logo.png"
      );
    },
  },
  components: { Dropdown, AppDialog, ValidatorForm, AppFormField },
});
</script>

<template>
  <div ref="navbarContainer" id="navbarContainer">
    <nav
      class="flex w-full flex-row flex-wrap lg:flex-no-wrap lg:flex-col lg:flex-row lg:items-center justify-around bg-limolane-bg-blue text-white py-6 fixed top-0 z-40"
      id="navbar"
      ref="navbar"
    >
      <div class="flex justify-between lg:w-3/4">
        <div
          v-if="userStore.isHotel"
          @click="showHotelWallpaper()"
          class="pl-12 lg:pl-0"
        >
          <img
            :src="customLogo"
            alt=""
            class="w-52 active:scale-90"
            @load="updateNavbarMargin()"
          />
        </div>
        <RouterLink to="/" class="pl-12 lg:pl-0" v-else-if="userStore.isAgency">
          <img
            :src="customLogo"
            alt=""
            class="w-52 active:scale-90"
            @load="updateNavbarMargin()"
          />
        </RouterLink>
        <RouterLink to="/" class="pl-12 lg:pl-0" v-else>
          <img
            src="/imgs/logo.png"
            alt=""
            class="w-52 active:scale-90"
            @load="updateNavbarMargin()"
          />
        </RouterLink>
        <div class="hidden lg:flex gap-6 items-center justify-end font-medium">
          <RouterLink
            to="/"
            class="hover:text-limolane-primary active:scale-90 transition-colors"
            >{{ $t("Home") }}</RouterLink
          >
          <RouterLink
            to="/trips"
            class="hover:text-limolane-primary active:scale-90 transition-colors"
            >{{ $t("YourTrips") }}</RouterLink
          >
          <RouterLink
            to="/trips/history"
            class="hover:text-limolane-primary active:scale-90 transition-colors"
            >{{ $t("Historical") }}</RouterLink
          >
          <RouterLink
            to="/billing"
            class="hover:text-limolane-primary active:scale-90 transition-colors"
            >{{ $t("Invoices") }}</RouterLink
          >
          <RouterLink
            to="/manage-user"
            class="hover:text-limolane-primary active:scale-90 transition-colors"
            >{{ $t("ManagingUsers") }}</RouterLink
          >

          <Dropdown
            :clearFunction="false"
            :placeholder="$t('Select')"
            :options="languagesOption"
            isDarkColor
            class="max-w-[20%]"
            cssInputClass="border-none"
            dropdownListCssClass="max-w-[3.5vw] min-w-[3.5vw] w-[3.5vw] flex flex-col items-center"
            :modelValue="
              LANG_ISO_CODE[
                userStore.currentCustomerConfig.notificationLanguage?.toUpperCase() as keyof typeof LANG_ISO_CODE
              ] || getBrowserLocale().toUpperCase()
            "
            @selected-option="onChangeLanguage"
          >
            <template #input="{ selectedOption, onOpenDropdown }">
              <button
                v-if="selectedOption"
                @click="onOpenDropdown"
                class="mr-5 mt-2"
              >
                <img
                  class="min-w-[2rem] max-w-[3rem]"
                  :src="selectedOption.image"
                />
              </button>
            </template>
            <template #item="{ option, selectOption }">
              <button @click="selectOption(option)">
                <img class="min-w-[2rem] max-w-[3rem]" :src="option.image" />
              </button>
            </template>
          </Dropdown>
          <div class="relative" @click="toggleNavbar" ref="profileDropdown">
            <!-- Momentaneo dato che non ricevo l'immagine profilo dal backend -->
            <div>
              <img
                v-if="!userStore.userInfo?.payload.profileIMG"
                class="h-12 cursor-pointer"
                src="../assets/icons/ProfileImgDefault.png"
                alt="Profile Limolane img default"
              />
              <img
                v-else
                class="h-12"
                :src="profileIMG"
                alt="Profile Limolane img"
              />
            </div>
            <div
              v-if="isNavbarProfileOpen"
              class="w-[220px] flex flex-col fixed bg-limolane-bg-blue p-2 rounded z-[999] top-20 right-20 shadow-2xl"
            >
              <RouterLink
                to="/profile"
                class="text-white pb-2 pl-1 hover:scale-105 active:scale-95 transition-transform"
              >
                {{ $t("Profile") }}
              </RouterLink>

              <RouterLink
                to="/profile/accounting"
                class="text-white pb-2 pl-1 hover:scale-105 active:scale-95 transition-transform"
              >
                {{ $t("PaymentAndInvoicing") }}
              </RouterLink>

              <RouterLink
                to="/partners"
                class="text-white pb-2 pl-1 hover:scale-105 active:scale-95 transition-transform"
                >{{ $t("Partners") }}</RouterLink
              >
              <!-- <RouterLink
              to="/profile/referall"
              class="text-white pb-2 pl-1 hover:scale-105 active:scale-95 transition-transform"
            >
              {{ $t("SuggestLimolane") }}
            </RouterLink> -->

              <div class="h-0.5 bg-[#919EAB3D]"></div>

              <button
                class="text-left py-1 pl-1 text-white hover:scale-105 active:scale-95 transition-transform"
                @click="userStore.logout()"
              >
                Logout
              </button>
            </div>
            <p v-else class="absolute"></p>
          </div>
        </div>
      </div>
      <Dropdown
        :clearFunction="false"
        :placeholder="$t('Select')"
        :options="languagesOption"
        isDarkColor
        class="lg:hidden max-w-[20%] ml-auto self-center"
        cssInputClass="border-none"
        dropdownListCssClass="max-w-[5vw] min-w-[5vw] w-[5vw] flex flex-col items-center"
        :modelValue="
          LANG_ISO_CODE[userStore.currentCustomerConfig.notificationLanguage] ||
          getBrowserLocale()
        "
        @selected-option="onChangeLanguage"
      >
        <template #input="{ selectedOption, onOpenDropdown }">
          <button
            v-if="selectedOption"
            @click="onOpenDropdown"
            class="mr-5 mt-2"
          >
            <img
              class="min-w-[3rem] max-w-[4rem]"
              :src="selectedOption.image"
            />
          </button>
        </template>
        <template #item="{ option, selectOption }">
          <button @click="selectOption(option)">
            <img class="min-w-[2rem] max-w-[3rem]" :src="option.image" />
          </button>
        </template>
      </Dropdown>
      <fa-icon
        icon="fa-solid fa-bars"
        @click="onToggleMobileMenu()"
        class="lg:hidden h-8 w-1/4 self-center"
      >
      </fa-icon>
      <div
        :class="{ hidden: !isNavbarHamburgerOpen, flex: isNavbarHamburgerOpen }"
        class="lg:hidden flex-col bg-limolane-bg-blue text-white py-6 font-medium text-lg w-3/4 items-center"
        @click.
      >
        <RouterLink
          to="/"
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="isNavbarHamburgerOpen = false"
          >{{ $t("Home") }}</RouterLink
        >
        <RouterLink
          to="/trips"
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="isNavbarHamburgerOpen = false"
          >{{ $t("YourTrips") }}</RouterLink
        >
        <RouterLink
          to="/trips/history"
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="isNavbarHamburgerOpen = false"
          >{{ $t("Historical") }}</RouterLink
        >
        <RouterLink
          to="/billing"
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="isNavbarHamburgerOpen = false"
          >{{ $t("Invoices") }}</RouterLink
        >
        <RouterLink
          to="/manage-user"
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="isNavbarHamburgerOpen = false"
          >{{ $t("ManagingUsers") }}</RouterLink
        >
        <RouterLink
          to="/profile"
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="isNavbarHamburgerOpen = false"
        >
          {{ $t("Profile") }}
        </RouterLink>

        <RouterLink
          to="/profile/accounting"
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="isNavbarHamburgerOpen = false"
        >
          {{ $t("PaymentAndInvoicing") }}
        </RouterLink>

        <!-- <RouterLink
        to="/profile/referall"
        class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
      >
        {{ $t("SuggestLimolane") }}
      </RouterLink> -->

        <div class="h-0.5 bg-[#919EAB3D]"></div>

        <button
          class="block px-12 py-4 hover:text-limolane-primary active:scale-90 transition-colors hover:bg-limolane-primary-alpha"
          @click="userStore.logout()"
        >
          Logout
        </button>
      </div>
    </nav>
  </div>
  <AppDialog
    :show="showHotelPassword"
    isConfirm
    @confirm="onCheckHotelPassword()"
    @discard="
      showHotelPassword = false;
      hotelPassword = '';
    "
  >
    <ValidatorForm
      @submit="onCheckHotelPassword()"
      class="w-full flex flex-col mb-5 z-50"
    >
      <span>{{ $t("InsertHotelPassword") }}</span>
      <AppFormField
        type="password"
        v-model="hotelPassword"
        rules="required"
        name="hotelPassword"
        :placeholder="$t('PasswordInsert')"
        @keyup.enter="onCheckHotelPassword()"
        class="p-2 appearance-none border-2 border-[#919eab3d] bg-white outline-0 focus:border-[#ACB7EC] w-full"
        :label="$t('Password')"
        :invalid-field-text="$t('Error_invalid_password')"
      />
    </ValidatorForm>
  </AppDialog>
</template>

<style scoped>
.router-link-active {
  color: #acb7ec;
}
</style>
