<script lang="ts">
import { defineComponent } from "vue";

import ButtonComponent from "./ButtonComponent.vue";
export default defineComponent({
  name: "DialogComponent",
  emits: ["confirm", "discard", "close"],
  props: {
    show: Boolean,
    title: String,
    bodyHtml: String,
    isConfirm: Boolean,
    hideDefaultButtons: Boolean,
  },
  components: { ButtonComponent },
});
</script>

<template>
  <div
    v-if="show"
    class="fixed inset-0 flex items-center justify-center bg-transparent backdrop-filter backdrop-blur-[24px] backdrop-brightness-90 z-40 p-2"
  >
    <div class="min-w-[33.3%] bg-white p-6 shadow-md rounded-lg">
      <slot>
        <h1 class="font-medium text-lg pb-4">{{ title }}</h1>
        <p class="pb-12" v-html="bodyHtml" v-if="bodyHtml"></p>
      </slot>
      <div class="flex justify-end gap-4" v-if="!hideDefaultButtons">
        <ButtonComponent
          v-if="isConfirm"
          :label="$t('ButtonClose')"
          typeStyle="primary-alternative"
          @click="$emit('discard')"
        />
        <ButtonComponent
          v-if="isConfirm"
          :label="$t('ButtonConfirm')"
          type="primary"
          @click="$emit('confirm')"
        />
        <ButtonComponent
          v-if="!isConfirm"
          :label="$t('ButtonClose')"
          typeStyle="primary-alternative"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>
