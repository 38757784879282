import type { CdcCliente } from "../models/customer/cdcCliente";
import type { ConfigModalitaPagamentoCliente } from "../models/customer/configModalitaPagamentoCliente";
import type { ConfigurazioneGeneraleCliente } from "../models/customer/configurazioneGeneraleCliente";
import type { ConfigurazionePersonalizzataCliente } from "../models/customer/configurazionePersonalizzataCliente";
import type { ClientiModel } from "../models/customer/customer";
import type { Favorite } from "../models/customer/favoriteAddress";
import type { CervedInfo } from "../models/customer/getCerved";
import type { ModalitaPagamentoCliente } from "../models/customer/modalitaPagamentoCliente";
import type { Owner } from "../models/customer/owners";
import type { CentroNotifiche } from "../models/trip/trip";
import Api, { parseApiResponse } from "./Api";

export default class CustomerApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + `v2/clienti`;
  // oldWebserviceApiUrl = import.meta.env.VITE_WS_URL;

  async getCervedInfo(
    partitaIva: string,
    codiceFiscale: string,
    companyId = 1,
  ) {
    const response = await this.post(
      this.baseApiUrl,
      `/GetCerved?idazienda=${companyId}`,
      {
        partitaIva,
        codiceFiscale,
      },
    );
    const companies = await parseApiResponse<
      Array<CervedInfo> | { found: boolean }
    >(response);
    if (!Array.isArray(companies) && companies.found === false) {
      return [];
    }
    return companies as CervedInfo[];
  }

  async getCustomerPaymentMethods(
    customerId: number,
    idazienda: number,
    personalTrip = false,
  ) {
    return parseApiResponse<Array<ConfigModalitaPagamentoCliente>>(
      await this.get(
        this.baseApiUrl,
        // `/configmodalitapagamento/ConfigModalitaPagamentoByClienteAzienda?idCliente=${customerId}&idazienda=${idazienda}&withdescription=true`
        `/configmodalitapagamento/CustomerPaymentMethods?idazienda=${idazienda}&personalTrip=${personalTrip}`,
      ),
    );
  }

  async getCustomerConfiguration(
    customerId: number,
    idAzienda: number,
    os = "web",
  ) {
    return parseApiResponse<ConfigurazioneGeneraleCliente>(
      await this.get(
        this.baseApiUrl,
        `/configurazionegenerale/ConfigGeneraleByClienteAzienda?idCliente=${customerId}&idazienda=${idAzienda}&os=${os}`,
      ),
    );
  }

  async getCdcs(customerId: number, idazienda: number) {
    return parseApiResponse<Array<CdcCliente>>(
      await this.get(
        this.baseApiUrl,
        `/cdc/${customerId}?idazienda=${idazienda}`,
      ),
    );
  }

  async insertCdc(cdc: CdcCliente, customerId: number, idazienda: number) {
    return parseApiResponse<Array<CdcCliente>>(
      await this.post(
        this.baseApiUrl,
        `/cdc/${customerId}?idazienda=${idazienda}`,
        cdc,
      ),
    );
  }

  async insertFavoriteAddress(address: Favorite, idazienda: number) {
    return parseApiResponse<number>(
      await this.post(
        this.baseApiUrl,
        `/favorite/InsertFavorite?idazienda=${idazienda}`,
        address,
      ),
    );
  }

  async getFavoriteAddresses(customerId: number, idazienda: number) {
    return parseApiResponse<Array<Favorite>>(
      await this.get(
        this.baseApiUrl,
        `/favorite/FavoriteByClienteAzienda?idcliente=${customerId}&idazienda=${idazienda}`,
      ),
    );
  }

  async updateFavoriteAddress(address: Favorite, idazienda: number) {
    return parseApiResponse<number>(
      await this.post(
        this.baseApiUrl,
        `/favorite/UpdateFavorite?idazienda=${idazienda}`,
        address,
      ),
    );
  }

  async deleteFavoriteAddress(id: number, idazienda: number) {
    return parseApiResponse<number>(
      await this.delete(
        this.baseApiUrl,
        `/favorite/DeleteFavoriteById?id=${id}&idazienda=${idazienda}`,
      ),
    );
  }

  async getAllPaymentMethods() {
    return parseApiResponse<Array<ModalitaPagamentoCliente>>(
      await this.get(
        this.baseApiUrl,
        `/modalitapagamentocliente/AllModalitaPagamento`,
      ),
    );
  }

  async updateCustomerConfiguration(
    config: ConfigurazioneGeneraleCliente,
    idAzienda: number,
    os = "web",
  ) {
    return parseApiResponse<ConfigurazioneGeneraleCliente>(
      await this.put(
        this.baseApiUrl,
        `/configurazionegenerale/UpdateConfigGenerale?idazienda=${idAzienda}&os=${os}`,
        config,
      ),
    );
  }

  async getCustomerCustomConfiguration(
    customerId: number,
    idAzienda: number,
    os = "web",
  ) {
    return parseApiResponse<Array<ConfigurazionePersonalizzataCliente>>(
      await this.get(
        this.baseApiUrl,
        `/configurazionepersonalizzata/ConfigPersonalizzataByClienteAzienda?idCliente=${customerId}&idazienda=${idAzienda}&os=${os}`,
      ),
    );
  }

  async getNotificationEmails(rideId: number, companyId: number) {
    return parseApiResponse<CentroNotifiche[]>(
      await this.get(
        this.baseApiUrl,
        `/all-notification-emails-for-trip?idazienda=${companyId}&tripId=${rideId}`,
      ),
    );
  }

  async getCompanyCustomer(customerId: number, companyId: number) {
    return parseApiResponse<ClientiModel>(
      await this.get(
        this.baseApiUrl,
        `/clientibyid?idAzienda=${companyId}&idCliente=${customerId}`,
      ),
    );
  }

  async deleteNotificationEmail(
    notificationEmail: CentroNotifiche,
    companyId: number,
  ) {
    return parseApiResponse(
      await this.post(
        this.baseApiUrl,
        `/delete-email?idazienda=${companyId}`,
        notificationEmail,
      ),
    );
  }

  async getOwnerById(ownerId: number, companyId: number) {
    return parseApiResponse<Owner>(
      await this.get(
        this.baseApiUrl,
        `/owner/get-owner-by-id?idazienda=${companyId}&ownerId=${ownerId}`,
      ),
    );
  }

  async getAllOwners() {
    return parseApiResponse<Owner[]>(
      await this.get(this.baseApiUrl, `/owner/all-owners`),
    );
  }
}
