import type { ApiResponseWithJWT } from "../models/api/response";
import type { ClientiModel } from "../models/customer/customer";
import type {
  PA_CessionarioCommittente,
  PA_CessionarioCommittenteOLD,
} from "../models/customer/paCessionarioCommittente";
import type { RegistrationRequest, UserInfo } from "../models/user/user";
import type { Registration } from "../models/user/user";
import Api, { checkApiResponseError, parseApiResponse } from "./Api";

type ValidateApiResponse = {
  access_token: string;
  userInfo: UserInfo;
};

export default class UserApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL;

  async login(email?: string, phone?: string, idazienda = 1, os = "web") {
    const response = await this.post(this.baseApiUrl, "registration/login", {
      email,
      phone,
      idazienda,
      os,
    });
    const user: Registration = JSON.parse(
      (await parseApiResponse<string>(response)) as string,
    );
    return user;
  }

  async validateOTP(
    email: string,
    phone: string,
    otp: string,
    idazienda = 1,
    os = "web",
  ) {
    const response = await this.post(this.baseApiUrl, "registration/burnotp", {
      email,
      phone,
      otp,
      idazienda,
      os,
    });
    const { access_token: accessToken, payload } =
      (await checkApiResponseError<string>(
        response,
      )) as ApiResponseWithJWT<string>;
    return {
      accessToken,
      user: JSON.parse(payload) as Registration,
    };
  }

  async validateSession() {
    const response = await this.get(this.baseApiUrl, "registration/validate");
    if (!response.ok) {
      throw new Error(
        `Cannot reach server (${response.statusText} ${response.status})`,
      );
    }
    const { access_token, userInfo }: ValidateApiResponse =
      await response.json();
    return {
      accessToken: access_token,
      user: userInfo,
    };
  }

  async signup(
    email?: string,
    prefix?: string,
    phone?: string,
    idazienda = 1,
    os = "web",
  ) {
    const response = await this.post(this.baseApiUrl, "registration/register", {
      email,
      phone,
      prefix,
      idazienda,
      os,
    });
    const user: Registration = JSON.parse(
      (await parseApiResponse<string>(response)) as string,
    );
    return user;
  }

  async finalize(user: RegistrationRequest, idazienda = 1, os = "web") {
    user.os = "web";
    const response = await this.post(this.baseApiUrl, "registration/finalize", {
      ...user,
      idazienda,
      os,
    });
    // if (!response.ok) {
    //   throw new Error(
    //     `Cannot reach server (${response.statusText} ${response.status})`
    //   );
    // }
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      const { access_token, payload, success, message } = await response.json();
      if (!success) {
        throw new Error(message);
      }
      return {
        accessToken: access_token as string,
        user: JSON.parse(payload) as UserInfo,
      };
    }
    throw new Error(await response.text());
  }

  /**
   *
   * @param compoanyId id azienda
   * @param companyName ragione sociale
   */
  async askCompanyAssociation(
    compoanyId: number,
    companyName: string,
    companyVatCode: string,
    userEmail: string,
  ) {
    const response = await this.post(
      this.baseApiUrl,
      "registration/richiestaassociazioneutenteaziendale",
      {
        id: compoanyId,
        ragioneSociale: companyName,
        partitaIva: companyVatCode,
        email: userEmail,
      },
    );
    await parseApiResponse<void>(response);
    return true;
  }

  async companyAssociationWithCode(companyCode: string) {
    const response = await this.get(
      this.baseApiUrl,
      `registration/autoassociazioneutenteaziendale?aziendacode=${companyCode}`,
    );
    await parseApiResponse<void>(response);
    return true;
  }

  async finalizeCompany(user: RegistrationRequest, idazienda = 1, os = "web") {
    const response = await this.post(
      this.baseApiUrl,
      "registration/company/finalize",
      {
        ...user,
        idazienda,
        os,
      },
    );
    if (!response.ok) {
      throw new Error(
        `Cannot reach server (${response.statusText} ${response.status})`,
      );
    }
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      const { access_token, payload, success, message } = await response.json();
      if (!success) {
        throw new Error(message);
      }
      return {
        accessToken: access_token as string,
        user: JSON.parse(payload) as UserInfo,
      };
    }
    throw new Error(await response.text());
  }

  async resendOTP(email?: string, phone?: string, idazienda = 1, os = "web") {
    const response = await this.post(
      this.baseApiUrl,
      "registration/resendotp",
      {
        email,
        phone,
        idazienda,
        os,
      },
    );
    const user: Registration = JSON.parse(
      (await parseApiResponse<string>(response)) as string,
    );
    return user;
  }

  async updateRegistration(req: RegistrationRequest) {
    req.os = "web";
    const response = await this.post(
      this.baseApiUrl,
      "registration/updateexistinguser",
      req,
    );
    const user: Registration = JSON.parse(
      (await parseApiResponse<string>(response)) as string,
    );
    return user;
  }

  async updateBillingData(
    customer: ClientiModel,
    accountingData: PA_CessionarioCommittenteOLD,
  ) {
    const response = await this.post(
      this.baseApiUrl,
      "registration/updatedatifatturazione",
      {
        cliente: JSON.stringify(customer),
        dati: JSON.stringify(accountingData),
      },
    );
    await parseApiResponse<void>(response);
    return true;
  }

  async getBillingData(customer: ClientiModel) {
    const response = await this.post(
      this.baseApiUrl,
      "registration/getdatifatturazione",
      {
        cliente: JSON.stringify(customer),
      },
    );
    const data = await parseApiResponse<string>(response);
    return JSON.parse(data) as PA_CessionarioCommittenteOLD;
  }

  async requestCompanyPaymentMethods() {
    return parseApiResponse<void>(
      await this.post(
        this.baseApiUrl,
        "registration/richiestapagamentiaziendali",
        {},
      ),
    );
  }
}
