﻿<script lang="ts">
import { defineComponent, type PropType } from "vue";

export default defineComponent({
  name: "ButtonComponent",
  emits: ["on-click"],
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    labelSize: {
      type: String,
      default: "",
    },
    submit: {
      type: Boolean,
      default: false,
    },
    typeStyle: {
      type: String as PropType<
        | "primary"
        | "primary-alternative"
        | "secondary"
        | "secondary-alternative"
        | "info"
        | "warning"
        | "text"
        | "primaryText"
      >,
      default: "primary",
    },
    size: {
      type: String as PropType<"small" | "medium" | "large">,
      default: "medium",
    },
    logoSrcRight: {
      type: String,
      default: "",
    },
    logoSrcLeft: {
      type: String,
      default: "",
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      let baseClass = "";
      if (!this.isDisabled) {
        switch (this.typeStyle) {
          case "primary":
            baseClass =
              "bg-[#ACB7EC] hover:bg-[#848dbb] active:scale-95 transition-colors text-[#0A2472] font-semibold shadow-[0px_8px_16px_0px_rgba(172,183,236,0.24)]";
            break;
          case "primary-alternative":
            baseClass =
              "bg-trasparent border-2 border-[#ACB7EC] box-content active:scale-95 transition-colors font-semibold text-[#ACB7EC]";
            break;
          case "secondary":
            baseClass =
              "bg-[#0A2472] hover:bg-[#152144] active:scale-95 transition-colors font-semibold text-white";
            break;
          case "secondary-alternative":
            baseClass =
              "bg-white hover:bg-[#5A5A5A] border-2 border-[#5A5A5A] box-content active:scale-95 transition-colors font-semibold text-[#5A5A5A] hover:text-white";
            break;
          case "info":
            baseClass =
              "bg-[#7878FF] hover:bg-[#3C3CB7] active:scale-95 transition-colors font-semibold text-white";
            break;
          case "warning":
            baseClass =
              "bg-[#FFE032] hover:bg-[#B79A19] active:scale-95 transition-colors font-semibold text-black";
            break;
          case "text":
            baseClass = "active:scale-95 font-semibold text-black";
            break;
          case "primaryText":
            baseClass = "text-[#ACB7EC] font-semibold active:scale-95";
            break;
          default:
            baseClass =
              "bg-[#ACB7EC] hover:bg-[#7743B7] active:scale-95 transition-colors font-semibold text-white";
        }
      }

      let sizeClass;
      switch (this.size) {
        case "small":
          sizeClass = "px-2.5 py-1";
          break;
        case "medium":
          sizeClass = "px-4 py-2.5";
          break;
        case "large":
          sizeClass = "px-6 py-3";
          break;
        default:
          sizeClass = "px-4 py-2.5";
      }

      let labelClass;
      switch (this.labelSize) {
        case "small":
          labelClass = "text-sm";
          break;
        case "medium":
          labelClass = "text-base";
          break;
        case "large":
          labelClass = "text-large";
          break;
        default:
          labelClass = "text-base";
      }

      return `${sizeClass} ${baseClass} ${labelClass}`;
    },
    disabledClass() {
      return this.isDisabled
        ? "bg-[#919EAB3D] text-[#919EABCC] hover:bg-[#C4CDD53D] font-medium cursor-not-allowed transition-colors"
        : "";
    },
    roundedClass() {
      return this.isRounded ? "rounded-[50px]" : "";
    },
  },
  methods: {
    onClick: function (event: Event) {
      if (!this.isDisabled) {
        if (!this.submit) {
          event.preventDefault();
        }
        this.$emit("on-click");
      }
    },
  },
});
</script>

<template>
  <button
    class="flex items-center uppercase"
    :class="[buttonClass, disabledClass, roundedClass]"
    @click="onClick"
    :disabled="isDisabled"
    :typeStyle="submit ? 'submit' : 'button'"
  >
    <img
      v-if="logoSrcLeft"
      :src="logoSrcLeft"
      alt="logo"
      class="icon-custom h-4 mr-1"
    />
    {{ label }}
    <img
      v-if="logoSrcRight"
      :src="logoSrcRight"
      alt="logo"
      class="icon-custom h-4 ml-1"
    />
  </button>
</template>

<!-- 
  PER USARE IL LOGO:

  - Nel componente importato, importare anche l'immagine (immagineTest).

  - Dichiararla nei Data:
  data() {
    return {
      immagineTest
    }
  }

  - Scriverla in :logoSrcRight="immagineTest" oppure :logoSrcLeft="immagineTest"
-->

<!--

  PER USARE IL SUBMIT

<ButtonComponent submit>Submit</ButtonComponent>  -----ESEGUIRA' IL SUBMIT------- 
<ButtonComponent>Click me</ButtonComponent>       -----NON ESEGUE IL SUBMIT------  
-->
