import { acceptHMRUpdate, defineStore } from "pinia";
import { tipofattura } from "../models/billing/fattureEmesse";
import type { Passeggero } from "../models/user/passeggero";
import BillingApi from "../services/BillingApi";
import CustomerApi from "../services/CustomerApi";
import InfoApi from "../services/InfoApi";
import PassengerApi from "../services/PassengerApi";
import TripApi from "../services/TripApi";
import { useUserStore } from "./user";

export const useCompanyStore = defineStore("company", {
  state: () => ({
    infoApi: new InfoApi(),
    customerApi: new CustomerApi(),
    passeggeriApi: new PassengerApi(),
    billingApi: new BillingApi(),
    tripApi: new TripApi(),
    userStore: useUserStore(),
    passengers: new Array<Passeggero>(),
    creatingAdminPassenger: false,
  }),
  actions: {
    async searchCompanies(vatCode: string) {
      if (vatCode.length < 3) {
        throw new Error("Minimum search query must be 3 characters long");
      }
      const userStore = useUserStore();
      try {
        this.uiStore.toggleLoading(true);
        const companies = await this.infoApi.searchCompany(
          vatCode,
          userStore.userInfo?.payload.iD_Azienda ??
            (<any>userStore.userInfo).idazienda,
        );
        return companies;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getCervedInfo(vatCode: string, fiscalCode: string) {
      if (vatCode.length < 11) {
        this.toast.error("Vat code must have 11 characters");
        return;
      }
      const userStore = useUserStore();
      this.uiStore.toggleLoading(true);
      try {
        const companies = await this.customerApi.getCervedInfo(
          vatCode,
          fiscalCode,
          userStore.userInfo?.payload.iD_Azienda,
        );
        return companies;
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async fetchPassengers(customerId: number) {
      let passengers = new Array<Passeggero>();
      if (isNaN(customerId) || customerId <= 0) {
        return passengers;
      }
      const userStore = useUserStore();
      this.uiStore.toggleLoading(true);
      try {
        this.passengers = await this.passeggeriApi.getPasseggeri(
          customerId,
          userStore.userInfo!.payload.iD_Azienda,
        );
        if (this.userStore.isPrivate) {
          return this.passengers;
        }
        return this.uniqueAndSortPassengers();
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
      return passengers;
    },

    uniqueAndSortPassengers() {
      // aggregate slaves and passengers or leave slaves
      const slaves = this.passengers.filter((e) => e.idPasseggero > 100000);
      const onlyPassengers = this.passengers.filter(
        (p) =>
          slaves.find((s) => s.idPasseggero === p.idPasseggero) === undefined,
      );
      let passengers = [
        ...slaves.map((s) => ({
          // map needed to get right passenger id (to save in ViaggiPasseggeri) and customer id (to fetch custom slave invoicing config)
          ...s,
          iD_Cliente: s.idPasseggero - 100000,
          idPasseggero:
            onlyPassengers.find((p) => p.email === s.email)?.idPasseggero ||
            s.idPasseggero,
        })),
        ...onlyPassengers.filter(
          (s) => slaves.find((p) => p.email === s.email) === undefined,
        ),
      ];
      // }

      return passengers.sort((a, b) => {
        const textA = (a.nome ?? "").toUpperCase();
        const textB = (b.nome ?? "").toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    },

    async downloadInvoice(id: number, idazienda: number) {
      try {
        this.uiStore.toggleLoading(true);
        const file = await this.billingApi.getInvoicePDF(id, idazienda);
        const doc = document.createElement("a");
        doc.href = URL.createObjectURL(file);
        doc.download = `${id}.pdf`;
        document.body.append(doc);
        doc.click();
        URL.revokeObjectURL(doc.href);
        document.body.removeChild(doc);
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async downloadReceipt(id: number, idazienda: number) {
      try {
        this.uiStore.toggleLoading(true);
        const file = await this.billingApi.getBillyPdf(id, idazienda);
        const doc = document.createElement("a");
        doc.href = URL.createObjectURL(file);
        doc.download = `${id}.pdf`;
        document.body.append(doc);
        doc.click();
        URL.revokeObjectURL(doc.href);
        document.body.removeChild(doc);
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getBilledTrips() {
      try {
        this.uiStore.toggleLoading(true);
        let nonEndedTrips = await this.tripApi.getViaggiConclusiCliente({
          pageIndex: 0,
          pageSize: 0,
        });
        nonEndedTrips = nonEndedTrips.filter(
          (t) => t.fatturato && t.iD_Fattura,
        );
        const bills = await this.billingApi.getEmitttedInvoices(
          {
            idAzienda: this.userStore.currentIdAzienda!,
            idCliente: this.userStore.currentIdCliente!,
            tipoFormato: tipofattura.fattura,
          },
          true,
          false,
          this.userStore.currentIdAzienda!,
        );
        return {
          bills,
          nonEndedTrips,
        };
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    async getCdcs() {
      if (!this.userStore.isMaster) {
        return [];
      }
      try {
        this.uiStore.toggleLoading(true);
        const res = await Promise.all(
          this.userStore.userInfo!.master.map((m) =>
            this.customerApi.getCdcs(
              m.id,
              this.userStore.userInfo!.payload.iD_Azienda,
            ),
          ),
        );
        return res.flat();
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
        return [];
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },

    // async insertCdc(cdc: CdcCliente) {
    //   try {
    //     this.uiStore.toggleLoading(true);
    //     const res = await this.customerApi.insertCdc(cdc, )
    //   } catch (e) {
    //     this.toast.error(this.i18n.global.t((e as Error).message));
    //   } finally {
    //     this.uiStore.toggleLoading(false);
    //   }
    // },

    async updatePassenger(p: Passeggero) {
      try {
        this.uiStore.toggleLoading(true);
        this.passeggeriApi.updatePassenger(p);
        this.toast.success(
          p.abilitato ? "Passeggero abilitato" : "Passeggero disabilitato",
        );
      } catch (e) {
        this.toast.error(this.i18n.global.t((e as Error).message));
      } finally {
        this.uiStore.toggleLoading(false);
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot)); // allows hot reloading of the store
}
