export enum tipofattura {
  fattura,
  ricevutafiscale,
  proforma,
  fatturaextra,
  nota_credito,
  autofattura,
  fattura_semplificata,
  nota_debito,
  scontrino = 99,
}

export interface FattureR {
  idFatturaR: number;
  iD_Fattura: number | null;
  data: string | null;
  descrizione: string;
  importo: number | null;
  aliquotaIva: number | null;
  iva: number | null;
  totale: number | null;
  iD_Viaggio: number | null;
  iDAliquota: number | null;
}

export interface FattureEmesse {
  idFattura: number;
  numero: string;
  iD_Cliente: number;
  modalitaPagamento: string;
  dataFattura: string | null;
  importo: number | null;
  iva: number | null;
  totale: number | null;
  inviata: boolean;
  pagata: boolean;
  data_Scadenza: string | null;
  gg_scadenza: number | null;
  ragioneSociale: string;
  cliente: string;
  tipoFattura: tipofattura;
  rimanenza: number | null;
  avvocato: boolean;
  perdita: boolean;
  note: string;
  numOrd: string;
  esito: string;
  list_fatture_r: FattureR[];
}
