import type { Autisti } from "../models/driver/driver";
import Api, { parseApiResponse } from "./Api";

export default class DriverApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + "v2/drivers/";

  async getDriverById(id: number) {
    const response = await this.get(this.baseApiUrl, `driver?iddriver=${id}`);
    const strPayload = await parseApiResponse<string>(response);
    return JSON.parse(strPayload) as Autisti;
  }
}
