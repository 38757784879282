import type { PreventivoModel } from "../models/trip/trip";
import type { Mezzi, MezziInfo } from "../models/vehicles/mezzi";
import Api, { parseApiResponse } from "./Api";

export default class VehicleApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + "v2/cars/";

  async getVehicleById(id: number, idazienda: number) {
    const response = await this.get(
      this.baseApiUrl,
      `getmezzo?idmezzo=${id}&idazienda=${idazienda}`,
    );
    return parseApiResponse<Mezzi>(response);
  }

  async getCategories(): Promise<Array<Partial<PreventivoModel>>> {
    // const strRes = await parseApiResponse<string>(
    //   await this.get(this.baseApiUrl, "categories")
    // );
    // return JSON.parse(strRes) as Array<MezziInfo>;
    return [
      {
        tipomezzo: {
          id: "s.png",
          title: "Luxury Class",
          description: "",
          summary: "",
        },
        childseat: 0,
        quantity: 1,
        totale: 0,
        baggage: "0",
        luggage: "0",
        seats: "3",
      },
      {
        tipomezzo: {
          id: "e.png",
          title: "Business Class",
          description: "",
          summary: "",
        },
        childseat: 0,
        quantity: 1,
        totale: 0,
        baggage: "0",
        luggage: "0",
        seats: "3",
      },
      {
        tipomezzo: {
          id: "t.png",
          title: "Green Class",
          description: "",
          summary: "",
        },
        childseat: 0,
        quantity: 1,
        totale: 0,
        baggage: "0",
        luggage: "0",
        seats: "3",
      },
      {
        tipomezzo: {
          id: "v.png",
          title: "Van",
          description: "",
          summary: "",
        },
        childseat: 0,
        quantity: 1,
        totale: 0,
        baggage: "0",
        luggage: "0",
        seats: "7",
      },
      {
        tipomezzo: {
          id: "b.png",
          title: "Premium Bus",
          description: "",
          summary: "",
        },
        childseat: 0,
        quantity: 1,
        totale: 0,
        baggage: "0",
        luggage: "0",
        seats: "0",
      },
      {
        tipomezzo: {
          id: "SUV",
          title: "Luxury SUV",
          description: "",
          summary: "",
        },
        childseat: 0,
        quantity: 1,
        totale: 0,
        baggage: "0",
        luggage: "0",
        seats: "3",
      },
    ];
  }

  async getAvailableVehicleTypes(companyId: number) {
    const response = await this.get(
      import.meta.env.VITE_API_URL,
      `v2/areemappa/GetAllTipoMezzo?idazienda=${companyId}`,
    );
    return parseApiResponse<TipoMezzoExt[]>(response);
  }
}
