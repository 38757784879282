import type { FattureTGlueWS } from "../models/billing/fattureWsGlueModel";
import type { FattureEmesse } from "../models/billing/fattureEmesse";
import type { FattureEmesseSearchRequest } from "../models/billing/fattureEmesseSearchRequest";
import Api, { parseApiResponse } from "./Api";

export default class BillingApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL + "v2/cicloattivo/fattureemesse";
  limolaneCAApi = import.meta.env.VITE_API_URL + "v2/cicloattivo/api/limolane";
  billyApi = import.meta.env.VITE_API_URL + "v2/cicloattivo/scontrini";

  async getInvoiceXML(id: number, idazienda: number) {
    const response = await this.get(
      this.baseApiUrl,
      `/scaricafattura?idfattura=${id}&idazienda=${idazienda}`,
    );
    return response.blob();
  }

  async getInvoicePDF(id: number, idazienda: number) {
    const response = await this.get(
      this.limolaneCAApi,
      `/invoices/${id}/export/pdf?idazienda=${idazienda}`,
    );
    if (!response.ok) {
      if (response.headers.get("Content-Type")?.includes("json")) {
        const data = await response.json();
        throw new Error(data.title);
      }
      throw new Error("GenericError");
    }
    return response.blob();
  }

  async getInvoice(id: number, idazienda: number) {
    return parseApiResponse<FattureTGlueWS>(
      await this.get(
        this.baseApiUrl,
        `/getfattura?idfattura=${id}&idazienda=${idazienda}`,
      ),
    );
  }

  async reportDiscrepancy(
    tripId: number,
    customerMessage: string,
    idazienda: number,
  ) {
    return parseApiResponse<null>(
      await this.post(
        this.baseApiUrl,
        `/invoice/difformity?idazienda=${idazienda}`,
        {
          tripId,
          customerMessage,
        },
      ),
    );
  }

  async getBillyPdf(billId: number, idazienda: number) {
    const response = await this.get(
      this.billyApi,
      `/pdf?idfattura=${billId}&idazienda=${idazienda}`,
    );
    return response.blob();
  }

  async getEmitttedInvoices(
    req: FattureEmesseSearchRequest,
    escludisezionali: boolean,
    chknonviate: boolean,
    idazienda: number,
  ) {
    return parseApiResponse<Array<FattureEmesse>>(
      await this.post(
        this.baseApiUrl,
        `/cercafattureemesse?escludisezionali=${escludisezionali}&chknonviate=${chknonviate}&idazienda=${idazienda}`,
        req,
      ),
    );
  }
}
