import type { MasterInfo } from "../models/info/master";
import Api, { parseApiResponse } from "./Api";

export default class InfoApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL;

  async searchCompany(vatCode: string, companyId = 1) {
    const response = await this.get(
      this.baseApiUrl,
      `info/searchazienda?idazienda=${companyId}&q=${vatCode}`,
    );
    if (!response.ok) {
      throw new Error(
        `Cannot reach server (${response.statusText} ${response.status})`,
      );
    }
    const companies: Array<MasterInfo> = await response.json();
    return companies;
  }
}
