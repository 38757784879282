import { CLIENT_NAME, CLIENT_VERSION } from "../../constants/utils";
import { dateToDateTime } from "../../utils/dateToDateTime";
import { i18n } from "../../utils/i18n";
import type { Passeggero } from "../user/passeggero";

export interface MinutiCliente {
  ServerTime: string;
  Tempo_inserimento: number | null;
  Tempo_aggiornamento: number | null;
  Tempo_cancellazione: number | null;
  NightTime: boolean;
}

export interface CalculateCostRequest {
  viaggio: Viaggio;
  preventivo: PreventivoModel[];
  clienteid: number;
  aziendaid: number;
  token: string;
  os: string;
  nazionePickup: string;
  nazioneDropOff: string;
  currency: ValuteListini;
}

export interface Viaggio {
  eliminato: string;
  source: string;
  version: string;
  platform: string;
  idViaggio: number;
  iD_Azienda: number;
  origin: Point | null;
  destination?: Point | null;
  quantity: number;
  data: string;
  dataFine: string;
  puntoPartenzaLat: number;
  puntoPartenzaLong: number;
  puntoDestinazioneLat?: number;
  puntoDestinazioneLong?: number;
  partenza: string;
  destinazione?: string;
  numeroPersone: number | string;
  nome_Cognome: string;
  tipoServizio: number;
  descrizione: string;
  descrizioneViaggio: any;
  cliente: string;
  note: string;
  noteGenerali: any;
  iDAliquota: number;
  aliquotaIva: number;
  modalitaPagamento: any;
  prenotato: number;
  inCorso: number;
  concluso: number;
  annullato: number;
  fatturato: number;
  prontoFattura: number;
  passeggero: string;
  numSeggiolini: number;
  iD_Cliente: number;
  email_cliente: string;
  spieg_extra: string;
  email_Prenota: string;
  telefono_Prenota: string;
  cellularePasseggero: string;
  indicazioneVolo: any;
  td: number;
  trasferito: number;
  ricevuto: number;
  condiviso: number;
  viaggioConfermatoAutista: number;
  route: string | null;
  tot_km: number;
  tot_H: number;
  cdc: string;
  commessa: string;
  campo1?: string;
  campo2?: string;
  campo3?: number;
  campo4?: string;
  idPagamentoNexi?: number;

  tempoAttesa: number | null;
  iD_Tipo_Servizio: number | null;
  iD_Autista: number | null;
  iD_Mezzo: number | null;
  tot_Costo_km: number | null;
  tot_Costo_H: number | null;
  tot_Costo_Forfait: number | null;
  importo: number | null;
  sconto: number | null;
  commissione: number | null;
  iD_Referente: number | null;
  dareAvere: string;
  ricevutaFiscale: boolean | null;
  inseritoDa: string;
  dataInserimennto: string | null;
  aggiornatoDa: string;
  ultimoAggiornamento: string | null;
  notifica: boolean | null;
  pathAllegatoDescrizione: string;
  pathDocumentoNote: string;
  avere: string;
  preventivo: string;
  iD_Fattura: number | null;
  scontoSuggerito: number | null;
  commissioneSuggerita: number | null;
  cartaCredito: boolean | null;
  iD_Viaggio_Collaboratore: number | null;
  iD_Collaboratore: number | null;
  numeroProgressivo: string;
  numeroProgressivo_Int: number | null;
  catMezzo: string;
  inFattura: boolean | null;
  statoCondiviso: number | null;
  tot_Minuti: number | null;
  importoFissato: boolean | null;
  kmMezzoInizio: number | null;
  kmMezzoFine: number | null;
  notificaInizio1Ora: boolean | null;
  puntoPartenza?: {
    lat: number;
    long: number;
  };
  puntoDestinazione?: {
    lat: number;
    long: number;
  };
  matricola: string;
  cDirezione: string;
  autistaOccasionale: string;
  notificaCliente: boolean | null;
  scontoPerc: number | null;
  iD_ModalitaPagamento: number | null;
  partnerAreaManager: string;
  subappaltato: boolean | null;
  fatturaPrivato: boolean | null;
  provPartenza: string;
  provDestinazione: string;
  pickupTime: string | null;
  instantPaymentEligible: boolean;
  pagato: boolean;
  totale: number;
  iD_ScontoCoupon?: number;
  contanti?: boolean;

  nazionePickup: string;
  nazioneDropOff: string;
  idAliquotaNazione: number;
  aliquotaNazione: number;
  descrizioneAliquotaNazione: string;
  naturaAliquotaNazione: string | null;
  idValutaRichiesta: number;
  valutaRichiesta: ValuteListiniKeys;
  cambioValutaDaEuro: number;
  valutaListino: ValuteListiniKeys;
  cambioValutaListino: number;

  speseTratta?: Array<SpeseTratta>;
  spese: number;
  speseImponibile: number;

  signFile: File;

  isStripePayment: boolean;

  favoriteTotalAmount?: number;
  favoriteTaxableAmount?: number;
  favoriteTaxAmount?: number;
  favoriteExpenseTaxableAmount?: number;

  favoriteCurrency: number;
  referenceCurrency: number;
  pricelistCurrency: number;
  referenceToFavoriteRate: number;
  referenceToPricelistRate: number;
}

export interface CustomerTripSummary {
  idViaggio: number;
  iD_Azienda: number;
  data: string;
  dataFine: string;
  partenza: string;
  destinazione: string;
  numeroPersone: string;
  mezzo: string;
  categoria: string;
  tipoServizio: string;
  isDisposizione: boolean;
  descrizione: string;
  descrizioneViaggio: string;
  cliente: string;
  note: string;
  importo: number;
  aliquotaIva: number;
  modalitaPagamento: string;
  sconto: number;
  commissione: number;
  dareAvere: string;
  avere: string;
  prenotato: boolean;
  inCorso: boolean;
  concluso: boolean;
  annullato: boolean;
  fatturato: boolean;
  prontoFattura: boolean;
  ricevutaFiscale: boolean;
  cartaCredito: boolean;
  contanti: boolean;
  passeggero: string;
  cdc: string;
  noteGenerali: string;
  notifica: boolean;
  iD_Cliente: number;
  iD_Referente: number | null;
  iD_Autista: number | null;
  tot_km: number | null;
  tot_H: number | null;
  tempoAttesa: number | null;
  trasferito: boolean;
  ricevuto: boolean;
  condiviso: boolean;
  iD_Viaggio_Collaboratore: number | null;
  iD_Collaboratore: number | null;
  viaggioConfermatoAutista: boolean;
  numeroProgressivo: string;
  numeroProgressivo_Int: number;
  inFattura: boolean;
  commessa: string;
  statoCondiviso: number;
  email_cliente: string;
  spieg_extra: string;
  td: number | null;
  campo1: string;
  campo2: string;
  campo3: number | null;
  campo4: string;
  ragioneSocialeAutista?: string;
  nomeAutista?: string;
  cellulareAutista?: string;
  spese: number;
  iDAliquota: number | null;
  aziendaCedente: string;
  aziendaAcquirente: string;
  importoFissato: boolean;
  autistaOccasionale: string;
  notificaCliente: boolean;
  partnerAreaManager: string;
  dataPagamento: string;
  esitoPagamento: string;
  iDPagamentoNexi: string;
  showNexiButton: boolean;
  importoDaSaldare: number | null;
  master: string;
  provinciaPartenza: string;
  provinciaDestinazione: string;
  isHotel: boolean;
  comunicazione: string;
  indicazioneVolo: string;
  isPagamentoDiretto: boolean;
  pickupTime: string | null;
  statoCondivisione: string;
  numero: string;
  iD_Fattura: number;
  targa: string;
  valutaRichiesta: ValuteListiniKeys;
  valutaListino: ValuteListiniKeys;
  cambioValutaDaEuro: number;
  voto: number;
  descrizioneSpese: number;
  speseImponibile: number;
  // FE internal associations
  passeggeri?: Array<ViaggiPasseggeriWithPasseggero>;

  favoriteTotalAmount?: number;
  favoriteTaxableAmount?: number;
  favoriteTaxAmount?: number;
  favoriteExpenseTaxableAmount?: number;

  favoriteCurrency: number;

  invoiceNumber: string;
}

export interface Point {
  lat: number;
  lng: number;
  nation: string;
}

export interface PreventivoModel {
  tipomezzo: TipoMezzo;
  childseat: number;
  quantity: number;
  baggage: string;
  seats: string;
  luggage: string;
  totale: number;
  iDListino: number;
  listino: string;
  aliquotaIva: number;
  descrizioneIva: string;
  currencyStart: ValuteListini | null;
  currencyEnd: ValuteListini | null;
  exchangeRate: number;
  AliquotaIva: number;
  DescrizioneIva: string;
  CurrencyStart: ValuteListiniKeys;
  CurrencyEnd: ValuteListiniKeys;
  ExchangeRate: number;
  IdAliquotaNazione: number;
  Natura: string | null;
  IdValutaRichiesta: number;
  ExchangeRateFromEuro: number;
  Legacy?: D;

  favoriteCurrency: number;
  FavoriteTaxAmount: number;
  FavoriteTaxableAmount: number;
  FavoriteTotalAmount: number;
  referenceCurrency: number;
  pricelistCurrency: number;
  referenceToFavoriteRate: number;
  referenceToPricelistRate: number;
}

export interface D {
  res: string;
  error: string;
  totale: number;
  sconto: number;
  descrittore: DescrittoreTariffa;
  idAzienda: number;
  scontocliente: number | null;
  incremento_temporaneo: number | null;
  bloccoarea: boolean | null;
  incrementoTipologia: number | null;
  incrementoarea: number | null;
  hotel: boolean | null;
  corporate: boolean | null;
  incrementoLastMinute: number | null;
  Listino: string;
  LockedPrices: boolean;
  TariffaFestivo: number | null;
  TariffaFestivoSpec: number | null;
  TariffaNotturno: number | null;
  Spese: SpeseTratta[];
}

export interface DescrittoreTariffa {
  Tariffa: number;
  TariffaFestivoSpec: number;
  TariffaNotturno: number;
  TariffaFestivo: number;
  TariffaTrasferta: number;
  TariffaImponibile: boolean;
  Sconto: number;
  ScontoPerc: number;
  TariffaFascia: number;
  TipoFascia: number;
  Tipo: number;
  TariffaChildSeat: number;
  Totale: number;
  IDTariffaFascia: number;
  IDListinoDettaglio: number;
  IDListino: number;
  Listino: string;
  TipoServizio: string;
  Ricompensa: number;
  Currency: ValuteListini | null;
}

export interface SpeseTratta {
  IDSpesa: number;
  ID_Viaggio: number | null;
  ID_Tratta: number | null;
  ID_TipoSpesa: number | null;
  Data: string | null;
  TipoSpesa: string;
  ID_AliquotaIva: number | null;
  Aliquota: string;
  Importo: number | null;
  Note: string;
  Eliminato: boolean | null;
  InFattura: boolean | null;
  ValAliquota: number;
  IDTestataFattura: number | null;
  NoteInFattura: boolean | null;
  ID_Azienda: number | null;
}

export interface TipoMezzo {
  id: string;
  title: string;
  description: string;
  summary: string;
}

export enum ValuteListini {
  EUR = 1,
  GBP = 2,
  CHF = 3,
  USD = 4,
}

export type ValuteListiniKeys = keyof typeof ValuteListini;

export interface ViaggiPasseggeri {
  id: number | null;
  idViaggio: number;
  passeggero: string | null;
  cellularePasseggero?: string | null;
  emailPasseggero: string | null;
  notePasseggero: string;
  notificaDettagli: boolean;
  idPasseggero?: number;
  idCliente?: number;
  creationDate: string | null;
  idAzienda: number;
}

export interface ViaggiPasseggeriWithPasseggero {
  viaggiPasseggeri: ViaggiPasseggeri;
  passeggero?: Passeggero;
}

export const TRIP_TO_INSERT_INITIAL_VALUE: Partial<Viaggio> = {
  eliminato: "0",
  source: CLIENT_NAME,
  version: CLIENT_VERSION,
  platform: "web",
  iD_Azienda: 0, //preso da userinfo
  iD_Cliente: 0,
  // Origin: {
  //   //oggetto latLng
  //   // lat: data.originlat, //ok oggetto
  //   // lng: data.originlng,
  // },
  quantity: 1, //per adesso 1
  data: dateToDateTime(new Date()), //data di oggi per adesso
  //Dt: ,//data ?
  //Ora: ,//data ?
  //Giorno: ,//data ?
  // dataFine: dateToDateTime(new Date()), //datetime di data di inizo (richiesta) + duration del tragitto
  //OraFine: null,//non ci interessa
  // PuntoPartenzaLat: data.originlat, //decimal con punti
  // PuntoPartenzaLong: data.originlng, //decimal con punti
  // PuntoDestinazioneLat: data.destlat, //decimal con punti
  // PuntoDestinazioneLong: data.destlng, //decimal con punti
  // Partenza: this.origPlace ? this.origPlace : null, //presa da autocomplete
  // Destinazione: this.destPlace ? this.destPlace : null, //presa da autocomplete
  numeroPersone: 1, //piu avanti verrà definito
  //PASSEGGERO
  // Nome_Cognome:
  // this.$store.state.user.nome + " " + this.$store.state.user.cognome, //passeggero: serve dopo
  //Mezzo: //non ci interessa
  //KmMezzoInizio//non ci interessa
  //KmMezzoFine//non ci interessa
  //ID_Mezzo//?
  //ID_Tipo_Servizio//?
  descrizione: "", // this.$store.state.tour.TipoServizio,
  descrizioneViaggio: "", //riempito dopo
  // Cliente: this.$store.state.user.ragioneSociale, //ragione sociale dell'utente
  note: "", //stringa vuota
  noteGenerali: null,
  //PathAllegatoDescrizione //non ci interessa
  //PathDocumentoNote //non ci interessa
  iDAliquota: 1, //1
  // ID_Tipo_Servizio: this.$store.state.tour.IDTipoServizio,
  // Tot_km: this.$store.state.tour.Km ?? 0,
  // Tot_H: this.$store.state.tour.Ore ?? 1,
  // Tot_Minuti: (this.$store.state.tour.Ore ?? 1) * 60,
  // TempoAttesa: this.$store.state.tour.Ore,
  // Destinazione: this.$store.state.tour.Descrizione,
  //ID_Fattura //non ci interessa
  //Autista //non ci interessa
  //Importo //inseriti dopo
  //Imponibile //inseriti dopo
  aliquotaIva: 10,
  modalitaPagamento: null, //inseriti dopo //stringa da riempiure con chiamata
  //Sconto //non ci interessa
  //ScontoSuggerito //non ci interessa
  //Commissione //non ci interessa
  //CommissioneSuggerita //non ci interessa
  //DareAvere //non ci interessa
  //Avere //non ci interessa

  prenotato: 1, //1
  inCorso: 0, //0
  concluso: 0, //0
  annullato: 0, //0
  fatturato: 0, //0
  prontoFattura: 0, //0
  ricevutaFiscale: false,
  //RicevutaFiscale //inseriti dopo
  //CartaCredito //inseriti dopo
  //Contanti //inseriti dopo

  // Passeggero:
  // this.$store.state.user.nome + " " + this.$store.state.user.cognome, //?
  numSeggiolini: 0, //inseriti dopo

  //CLIENTI AZIENDALI
  //cdc: //centro di costo

  //ID_Referente //non ci interessa
  //ID_Autista //non ci interessa

  //tot_km: ,//vengono fuori dalla route della maps js decimal
  //Tot_H: ,//vengono fuori dalla route della maps js decimal

  // Email_cliente: this.$store.state.user.email,
  spieg_extra: "", //inseriti dopo
  //Campo1: //inseriti dopo
  //Campo2: //inseriti dopo
  //Campo3: //inseriti dopo
  //Campo4: //inseriti dopo

  route: null, //risultato json del tragitto richiesto a google / quando mettiamo orario  la mappa restituisce un json e poi verrà salvata per evitare di fare altre chiamate a Google / se la mappa ha gia questo oggetto non fa ltre chiamate a maps ma prende questo oggetto

  //Tot_minuti:  //stesso come tot_H
  // Email_Prenota: this.$store.state.user.email,

  // Telefono_Prenota: this.$store.state.user.telefono, //TelefonoPrenota?
  // CellularePasseggero: this.$store.state.user.cellulare,

  indicazioneVolo: null, //inseriti dopo
  //Preventivo: //stesso come Importo (imponibile + iva)

  td: 57, //57 > trasferimento / 2 > disposizione / 3 > tour
  tipoServizio: 57, //per adesso 57
  iD_Tipo_Servizio: null,

  //Categoria: //inseriti dopo quando selezioniamo il mezzo
  //CatMezzo: //quando selezioniamo il mezzo

  trasferito: 0,
  ricevuto: 0,
  condiviso: 0,
  viaggioConfermatoAutista: 0,
  partenza: "",
  destinazione: "",
  isStripePayment: false,
};

export const TRIP_STATUSES = {
  Terminato: {
    name: i18n.global.t("TripStatusEnded"),
    textColor: "text-[#212B36]",
    bgColor: "bg-[#FF7F27]",
    isInvoicingStatus: false,
  },
  InCorso: {
    name: i18n.global.t("TripStatusRunning"),
    textColor: "text-[#28BEAD]",
    bgColor: "bg-[#2CD9C529]",
    isInvoicingStatus: false,
  },
  Confermato: {
    name: i18n.global.t("TripStatusConfirmed"),
    textColor: "text-[#559011]",
    bgColor: "bg-[#89C92229]",
    isInvoicingStatus: false,
  },
  Notificato: {
    name: i18n.global.t("TripStatusNotified"),
    textColor: "text-[#B79A19]",
    bgColor: "bg-[#FFC10729]",
    isInvoicingStatus: false,
  },
  Assegnato: {
    name: i18n.global.t("TripStatusAssigned"),
    textColor: "text-[#3C3CB7]",
    bgColor: "bg-[#7878FF29]",
    isInvoicingStatus: false,
  },
  Prenotato: {
    name: i18n.global.t("TripStatusReserved"),
    textColor: "text-[#7743B7]",
    bgColor: "bg-[#ACB7EC29]",
    isInvoicingStatus: false,
  },
  Inserito: {
    name: i18n.global.t("TripStatusInserted"),
    textColor: "text-[#7743B7]",
    bgColor: "bg-[#ACB7EC29]",
    isInvoicingStatus: false,
  },
  ReadyBill: {
    name: i18n.global.t("YourTripsStatusReadyBill"),
    textColor: "text-[#2D99FF]",
    bgColor: "bg-[#2D99FF29]",
    isInvoicingStatus: true,
  },
  WaitingBill: {
    name: i18n.global.t("TripStatusWaitingInvoice"),
    textColor: "text-[#FF6C40]",
    bgColor: "bg-[#FF6C4029]",
    isInvoicingStatus: true,
  },
  Invoiced: {
    name: i18n.global.t("TripStatusInvoiced"),
    textColor: "text-[#FF6C40]",
    bgColor: "bg-[#FF6C4029]",
    isInvoicingStatus: true,
  },
  Discarded: {
    name: i18n.global.t("TripStatusDiscarded"),
    textColor: "text-[#B71E16]",
    bgColor: "bg-[#FF572D29]",
    isInvoicingStatus: false,
  },
};

export type TRIP_STATUS_KEYS = keyof typeof TRIP_STATUSES;

export interface CentroNotifiche {
  id?: number;
  idViaggio?: number;
  idViaggioPasseggero?: number;
  idCliente?: number;
  destinatario: string;
  nomeCliente: string;
  abilitato: boolean;
  isNew?: boolean; // FE
  idAzienda: number;
}

export interface MinutiClienteV2 {
  serverTime: Date;
  firstReservationTime: string;
  firstModificationTime: string;
  firstCancellationTime: string;
  tempo_inserimento: number | null;
  tempo_aggiornamento: number | null;
  tempo_cancellazione: number | null;
  nightTime: boolean;
  rideTimeZone: string;
  localTime: string;
  localFirstReservationTime: string;
  localFirstModificationTime: string;
  localFirstCancellationTime: string;
  reservationTimeKind: string;
}
