import type { App } from "vue";

interface IdleOptions {
  timeout: number;
  onIdle: () => void;
}

export default {
  install(app: App, options: IdleOptions) {
    let timeoutId: number;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(options.onIdle, options.timeout);
    };

    const events = ["mousemove", "keydown", "scroll", "touchstart"];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer();
  },
};
