import it from "./it.json";
import en from "./en.json";
import fr from "./fr.json";

export const languages = {
  "it-IT": it,
  "en-GB": en,
  "fr-FR": fr,
};

export type LocaleSchema = typeof it;
