import type { Passeggero } from "../models/user/passeggero";
import Api, { parseApiResponse } from "./Api";

export default class PassengerApi extends Api {
  baseApiUrl = import.meta.env.VITE_API_URL;
  v1BaseApiUrl = this.baseApiUrl + "passeggeri/";
  v2BaseApiUrl = this.baseApiUrl + "v2/clienti/passeggeri/";

  async getPasseggeri(idcliente: number, idazienda: number, os = "web") {
    const response = await this.get(
      this.v2BaseApiUrl,
      `PasseggeriByCliente?idcliente=${idcliente}&idazienda=${idazienda}`,
    );
    return parseApiResponse<Array<Passeggero>>(response);
  }

  async createPassenger(
    passenger: Passeggero,
    createUser: boolean,
    visualizzaPrezzoSlave: boolean,
  ) {
    return parseApiResponse<number>(
      await this.post(
        this.v2BaseApiUrl,
        `InsertPasseggero?createUser=${createUser}&visualizzaPrezzoSlave=${
          visualizzaPrezzoSlave || false
        }`,
        passenger,
      ),
    );
  }

  async updatePassenger(passenger: Passeggero) {
    return parseApiResponse<number>(
      await this.put(this.v2BaseApiUrl, "UpdatePasseggero", passenger),
    );
  }

  async deletePassenger(id: number) {
    return parseApiResponse<number>(
      await this.delete(this.v2BaseApiUrl, `DeletePasseggeroById?id=${id}`),
    );
  }

  async getPassengerById(id: number) {
    return parseApiResponse<Passeggero>(
      await this.get(this.v2BaseApiUrl, `PasseggeroById?id=${id}`),
    );
  }
}
